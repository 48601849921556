@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap');
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --work-sans-font: 'Work Sans', sans-serif;
  --ghost-white-color: #fafafc;
  --dodger-blue-color: #33d2fd;
  --blue-color: #233148;
  --lynch-color: #6a6d89;
  --white-color: #fff;
  --transition: all 0.3s ease-in-out;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  font-family: var(--work-sans-font);
  font-size: 1rem;
  line-height: 1.6;
}

ul {
  list-style-type: none;
}

a {
  color: var(--blue-color);
  text-decoration: none;
}

img {
  width: 100%;
  display: block;
}

button {
  outline: 0;
  font-family: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: var(--blue-color);
}

h1,
h2,
h3 {
  margin-top: 0;
  color: var(--blue-color);
}

.white {
  color: white !important;
}

/* navbar */
.navbar {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  margin-top: 16px;
}

.trainNav {
  z-index: 1001;
  margin-top: 4px;
}

.trainMobileNav {
  margin-top: 16px;
}

.navbar-brand img {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}

.navbar-brand-collab{
display: flex;
}

.navbar-brand-collab img {
  height: 125px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}

.collab{
  filter: invert(1);
}

.nav-content {
  display: flex;
  justify-content: space-between;
  padding-top: 4.8rem;
  padding-bottom: 4.8rem;
  height: 200px;
}

.brand-and-toggler {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/* show navbar  */

/* header */
.header {
  /* background: url("./images/purpleBackground.jpg") center/cover no-repeat; */
  background-color: #121212;
  background-image: linear-gradient(-315deg, #000000 0%, #505050 100%);
}


.header-blue {
  filter: brightness(0.7);
  background: url("./images/main-bg.png") center/cover no-repeat !important;
}

.header-purple {
  background: url("./images/purpleBackground.jpg") center/cover no-repeat !important;
}

.header-purple2 {
  background: url("./images/purpleBackground2.jpg") center/cover no-repeat !important;
}

.header-purple3 {
  background: url("./images/purpleBackground3.jpg") center/cover no-repeat !important;
}

.header-purple4 {
  background: url("./images/purpleBackground4.jpg") center/cover no-repeat !important;
}

.header-purple5 {
  background: url("./images/purpleBackground5.jpg") center/cover no-repeat !important;
}

.shortHeader {
  background-color: #cfcfcf;
  background-image: linear-gradient(-315deg, #000000 0%, #505050 100%);
  height: 250px;
  padding-bottom: 300px;
}

.black {
  color: #000000;
}

.header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.header-text {
  margin-top: 21rem;
}

.header-text h2 {
  font-size: 4.5rem;
  font-weight: 700;
  max-width: 514px;
  line-height: 1.57;
}

.header-text p {
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 1.5rem;
}

.btn-group {
  margin-top: 4.5rem;
}

.btn-group button {
  height: 40px;
  padding: 0 3.5rem;
  border-radius: 5px;
  border: 1px solid var(--white-color);
  font-size: 1.4rem;
  font-weight: 600;
  width: 100%;
}

.btn-group button:first-child {
  background-color: var(--white-color);
  margin-right: 25px;
  margin-bottom: 14px;
  transition: var(--transition);
}

.btn-group button:last-child {
  color: var(--white-color);
  transition: var(--transition);
}

.btn-group button:first-child:hover {
  background-color: transparent;
  color: var(--white-color);
}

.btn-group button:last-child:hover {
  background-color: var(--white-color);
  color: var(--blue-color);
}

.oneButton:hover {
  background-color: transparent !important;
  color: var(--white-color) !important;
}



/* section one */
.section-one {
  position: relative;
  padding: 64px 0;

}


.section-one-content {
  position: relative;
  padding-top: 11.5rem;
  padding-bottom: 11.5rem;
  min-height: 125px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.section-one-content .img img {
  width: 260px;
  display: none;
}

.grid-item {
  padding: 6rem 3rem 4rem;
  box-shadow: 0 7px 14px -2px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  margin-bottom: 7rem;
}

.grid-item:hover {
  transform: scale(1.05);
}

.grid-item:last-child {
  margin-bottom: 0;
}

.grid-item h3 {
  font-size: 1.4rem;
  font-weight: 600;
  padding: 3.5rem 0 3rem 0;
}

.grid-item img {
  width: 64px;
  margin: 0 auto;
}

/* section two */
.section-two {
  padding: 9rem 0;
}

.section-two-content {
  display: grid;
  row-gap: 9rem;
}

.section-two-content .info .text {
  max-width: 460px;
  margin-right: auto;
  margin-left: auto;
}

.section-two-content .info .btn {
  margin-top: 3rem;
}

.accordion-item {
  box-shadow: 0 4px 10px -2px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  padding: 3rem 4rem;
  transition: var(--transition);
  display: grid;
  grid-template-columns: 50px auto;
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
}

.accordion-item-content {
  transition: var(--transition);
}

.accordion-item-content p.text {
  transition: var(--transition);
}

.accordion-item:hover {
  background-color: #0000FF;
}

.accordion-item:hover .accordion-title {
  color: white;
}

.littleLogo {
  transition: all 300ms ease;

}

.accordion-item:hover .littleLogo {
  filter: invert(1);
}

.accordion-item-content span {
  font-size: 1.5rem;
  font-weight: 600;
  display: block;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: var(--transition);
}

.accordion-item-content span:hover {
  color: var(--white-color);
}

.accordion-item-icon img {
  width: 30px;
  margin-right: 2.5rem;
}

/* accordion js */
.accordion-item-content p {
  max-height: 0;
  overflow: hidden;
}

/* section three */

.section-five {
  color: #000000;
  padding: 6rem 0 11rem 0;
  position: relative;
}

.facebookTitle {
  max-width: 800px;
  margin: 0 auto;
}

/* section four */
.section-four {
  background-color: #cfcfcf;
  background-image: linear-gradient(45deg, #535353 0%, #000000 50%, #535353 100%);
  padding: 6rem 0 11rem 0;
  position: relative;
}

.section-four-blue {
  background: url("./images/vector-bg.png") center/cover no-repeat;
  padding: 11rem 0;
  position: relative;
}

.section-four-content {
  display: grid;
  row-gap: 9rem;
}

.section-four-content .img img {
  max-width: 260px;
  margin-right: auto;
  margin-left: auto;
}

.section-four-content .info p.text {
  color: var(--white-color);
  margin-bottom: 4rem;
  max-width: 470px;
  margin-right: auto;
  margin-left: auto;
}

.get-app-btn {
  position: absolute;
  bottom: 48px;
  left: 50%;
  transform: translateX(-50%);
  height: 70px;
  min-width: 370px;
  text-align: center;
  line-height: 71px;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 700;
  box-shadow: 0 0 30px 0px rgba(255, 7, 95, 0.2);
  background: linear-gradient(#fe7570, #f6387d);
}

.btn.get-app-btn:hover {
  transform: translate(-50%, 3px);
}

/* footer */

.footer {
  padding-top: 32px;
}

.footer-content {
  max-width: 800px;
  position: relative;
}

.footer-bottom {
  margin-bottom: 8.8rem;
}

.footer-bottom .section-title h2 {
  margin-bottom: 0;
}

.newsletter-form {
  margin-top: 5rem;
  display: grid;
  row-gap: 3rem;
}

.newsletter-form .form-control {
  width: 100%;
  background-color: #eeeeee;
  box-shadow: 0 7px 14px -2px rgba(0, 0, 0, 0.25);
  border: none;
  font-size: 1.5rem;
  outline: 0;
  height: 50px;
  padding: 0 1.4rem;
}

.newsletter-form .btn.submit-btn {
  text-transform: uppercase;
  color: white;
  background-color: #242424;
  border-radius: 0;
  padding: 0;
}

.footer-icons {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}

.footer-icons li {
  margin-right: 4.5rem;
}

.footer-icons li:last-child {
  margin-right: 0;
}

.footer-icons li a {
  transition: var(--transition);
}

.footer-icons li a:hover {
  opacity: 0.8;
}

.footer-end {
  padding: 4rem 0;
}

.footer-end span {
  font-size: 1.4rem;
  font-weight: 400;
}

.bottom-footer {
  background-color: #242424;
  padding: 20px;
  color: #f0f0f0;
}

.bottom-footer-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1200px;
  /* You can adjust this to your preference */
  margin: 0 auto;
  /* Centers the container */
}

.bottom-footer-section {
  width: 33%;
  /* Adjusted to three sections now */
  margin-bottom: 20px;
  text-align: center;
  /* Centers the text within the section */
}

.bottom-footer-bottom {
  text-align: center;
  padding: 10px;
  background-color: #1a1a1a;
  color: #f0f0f0;
}

.bottom-footer-section a {
  color: #f0f0f0;
  text-decoration: none;
  display: block;
  margin: 5px 0;
}

.bottom-footer-section a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .bottom-footer-section {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .bottom-footer-section {
    width: 100%;
  }
}




/* Media Queries */

.show-nav-button,
.hide-nav-button {
  display: none;
}

@media(max-width: 950px) {
  .scroll-to-section {
    font-size: large !important;
    width: 400px;
    padding: 4px 8px !important;
    margin: 4px 2px !important;
    border: 1px solid rgba(173, 216, 230, 0.32);
  }

  .stickyNav {
    flex-direction: column !important;
    height: auto !important;
    width: max-content !important;
    padding: 4px 16px;
  }

  .show-nav-button,
  .hide-nav-button {
    display: flex;
    margin-top: 16px;
    color: white;
    font-size: medium;
  }

  .show-nav-button {
    margin-top: 8px;
    font-size: x-large;
  }
}


@media(max-width: 800px) {
  .subTitle2 {
    margin: 80px 0 40px 0 !important;
  }

  .floatingNav {
    width: max-content !important;
    padding: 4px 16px;
  }

  .floatingNavContainer {
    flex-direction: column !important;
    width: max-content !important;
  }

  .floatingNavOption {
    width: 400px;
    font-size: large;
    padding: 2px 4px !important;
    margin: 2px 1px !important;
    border: 1px solid rgba(173, 216, 230, 0.32);
  }
}

@media(max-width: 600px) {
  .scroll-to-section {
    font-size: small !important;
    width: 200px;
    padding: 2px 4px !important;
    margin: 2px 1px !important;
    border: 1px solid rgba(173, 216, 230, 0.32);
  }

  .stickyNav {
    flex-direction: column !important;
    height: auto !important;
    width: max-content !important;
    padding: 4px 16px;
  }

  .show-nav-button,
  .hide-nav-button {
    display: flex;
    margin-top: 10px;
    color: white;
    font-size: small;
  }

  .show-nav-button {
    margin-top: 6px;
    font-size: large;
  }

  .floatingNav {
    width: max-content !important;
    padding: 4px 16px;
  }

  .floatingNavContainer {
    flex-direction: column !important;
    width: max-content !important;
  }

  .floatingNavOption {
    width: 200px;
    padding: 2px 4px !important;
    margin: 2px 1px !important;
    border: 1px solid rgba(173, 216, 230, 0.32);
  }
}

@media(min-width: 600px) {

  .btn-group button {
    width: auto;
  }

  .section-one-content .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
  }

  .grid-item {
    width: 270px;
    margin-right: auto;
    margin-left: auto;
  }

  .grid-item:last-child {
    margin-bottom: 7rem;
  }

  .grid-item:nth-child(odd) {
    margin-right: 0;
  }

  .grid-item:nth-child(even) {
    margin-left: 0;
  }

  .newsletter-form {
    grid-template-columns: 1 170px;
    column-gap: 30px;
  }
}

@media(min-width: 800px) {

  .section-one-content .grid {
    column-gap: 7rem;
  }

  .footer-end .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-align: left;
  }

  .footer-icons {
    margin-top: 0;
    justify-content: flex-end;
  }

}

@media(min-width: 992px) {
  .section-two-content {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 14.5rem;
    align-items: center;
  }

  .section-two-content .info {
    text-align: right;
  }

  .section-four-content {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 16rem;
    align-items: center;
  }

  .section-four-content .info {
    text-align: left;
  }

  .section-four-content .img img {
    margin-right: 0;
  }

}

@media(min-width: 1200px) {
  .section-one-content .img img {
    display: block;
  }

  .section-one-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }
}

.holder {
  overflow-x: hidden;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 2.5rem;
}

/* text classes */
.text-white {
  color: var(--white-color);
}

.text-blue {
  color: black;
}

.text-center {
  text-align: center;

}

.text {
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--lynch-color);
  line-height: 1.85;
}

.ndsu {
  font-size: 1.4rem;
  margin-top: 16px !important;
  font-weight: 600;
  color: black;
}

.knowledgeTitle {
  font-size: 16px !important;
  text-align: center !important;
  margin: 0 !important;
}

.sessionJoinLink {
  color: black;
  text-decoration: underline;
  font-weight: 600;
}

/* backgrounds */
.bg-white {
  background-color: var(--white-color);
  display: block;
}

.bg-blue {
  background-color: var(--blue-color);
}

.bg-black {
  background-color: #242424;
}

.black {
  color: #000000 !important;
}

input::-webkit-input-placeholder {
  color: #242424;
}

input::-moz-placeholder {
  color: #242424;
}

input:-moz-placeholder {
  color: #242424;
}

input:-ms-input-placeholder {
  color: #242424;
}

textarea::-webkit-input-placeholder {
  color: #242424;
}

textarea::-moz-placeholder {
  color: #242424;
}

textarea:-moz-placeholder {
  color: #242424;
}

textarea:-ms-input-placeholder {
  color: #242424;
}

.bg-ghost-white {
  background-color: var(--ghost-white-color);
}

/* button */
.btn {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--white-color);
  background-color: #0000FF;
  display: inline-block;
  line-height: 50px;
  height: 50px;
  padding: 0 45px;
  border-radius: 5px;
  transition: var(--transition);
}

.btn:hover {
  transform: translateY(3px);
}

/* section title */
.section-title h2 {
  font-size: 3rem;
  font-weight: 800;
  color: var(--blue-color);
  margin-bottom: 4rem;
}

.noBottomMargin {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.logo {
  filter: invert(100%);
}

.extraMargin {
  margin-bottom: 32px;

}

.logo:hover {
  filter: invert(0%);
}

.logo {
  filter: invert(100%);
}

.logo:hover {
  filter: invert(25%);
}

.pollTitle {
  font-size: 24px;
  color: red;
  margin-top: 16px;
}

.dueDate {
  font-size: 16px;
}

.day {
  font-size: 32px;
  color: white;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.school {
  font-size: 16px;
  color: white;
  font-weight: 400;
  letter-spacing: 2px;
  margin-top: 16px;
}

.title {
  font-size: 40px !important;
  text-align: center;
  margin: 0 0 32px 0;
  padding: 0 16px;
}

.mePic {
  border-radius: 50%;
}

.directions {
  font-size: 20px !important;
  text-align: center;
  margin: 0 auto 32px auto ;
  padding: 8px 16px;
  width: 90%;
  max-width: 800px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgb(255, 0, 0, .4);
  color: #000000;
}

.directions p{
  color: #000000;
  font-size: 18px;
  margin: 0px;
}

.greenDirections{
  box-shadow: 0px 0px 20px rgb(4, 255, 0);
}

.subTitle {
  margin: 64px 0 80px 0;
  padding: 0 16px;
  font-size: large !important;
  font-weight: 600;
  text-align: center;
}

.topSubTitle {
  margin-top: 104px;
}

.subTitle2 {
  margin: 0 0 40px 0;
  padding: 0 16px;
  font-size: large !important;
  font-weight: 600;
  text-align: center;
}

.bigSub {
  font-size: x-large !important;
}

.subTitle3 {
  margin-top: 60px;
}

.pollsTitle {
  font-size: 24px;
  width: 100%;
  text-align: center;
  margin: 48px 0 24px;
}

.textArea {
  padding: 8px !important;
  height: 100px !important;
}

.success {
  background-color: lightgreen;
  width: 100%;
  max-width: 400px;
  height: 300px;
  font-size: 32px;
  color: white;
  border-radius: 16px;
  margin: 32px auto;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.small {
  font-size: small;
}

ul {
  display: flex;
}

li {
  list-style-type: none;
}

.logo {
  width: 160px;
}

.presentationLogo{
  width: 60% !important;
}


/* 

LANDING

*/

/* 

HIGHLIGHTS 

*/

/* 

FEATURES 

*/

#features {
  padding-top: 8vw;
  background-color: rgba(115, 66, 214, 0.1);
}

/* 

EXPLORE

*/

#explore {
  background-color: rgba(115, 66, 214, 0.1);
}

/* 

FOOTER

*/

/* Testimonials */
#content {
  max-width: 900px;
  margin: 0 auto;
}

.stats {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.satisfactionCardTitle {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
}

.satisfactionCard {
  flex: 1;
  margin: 1em;
  padding: 2em;
  border-radius: 1em;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.percentGraphic {
  width: 50%;
  max-width: 150px;
  margin-top: 1em;
}

.testimonial {
  margin-bottom: 64px;
}

.testimonial .stars {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
  font-size: 18px;
  color: gold;
}

.testimonial blockquote {
  margin: 10px 10px 0;
  font-size: 16px;
  background: #efefef;
  padding: 20px 60px;
  position: relative;
  border: none;
  border-radius: 8px;
  font-style: italic;
}

.testimonial blockquote:before,
.testimonial blockquote:after {
  content: "\201C";
  position: absolute;
  font-size: 80px;
  line-height: 1;
  color: #757f9a;
  font-style: normal;
}

.testimonial blockquote:before {
  top: 0;
  left: 10px;
}

.testimonial blockquote:after {
  content: "\201D";
  right: 10px;
  bottom: -0.5em;
}

.testimonial div {
  width: 0;
  height: 0;
  border-left: 0 solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #efefef;
  margin: 0 0 0 60px;
}

.testimonial p {
  margin: 8px 0 0 20px;
  text-align: left;
  color: #242424;
  font-size: 12px;
}

.back {
  color: white;
  text-decoration: underline;
  font-size: 16px;
  z-index: 11;
}

.topLinks {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 32px;
}

.back:hover {
  color: #373737;
}

/**
SIGN IN
**/

.signInBody {
  background-color: #080710;
}

.background {
  width: 430px;
  height: 520px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background-color: #000000;
}

.background .shape {
  height: 200px;
  width: 200px;
  position: absolute;
  border-radius: 50%;
}

.shape:first-child {
  background: linear-gradient(#1845ad,
      #23a2f6);
  left: -80px;
  top: -80px;
}

.shape:last-child {
  background: linear-gradient(to right,
      #ff512f,
      #f09819);
  right: -30px;
  bottom: -80px;
}

.form {
  height: 520px;
  width: 400px;
  background-color: rgba(255, 255, 255, 0.13);
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
  padding: 50px 35px;
}

.form * {
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  letter-spacing: 0.5px;
  outline: none;
  border: none;
}

.form h3 {
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
  text-align: center;
}

.label {
  display: block;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
}

.input {
  display: block;
  height: 50px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 3px;
  padding: 0 10px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 300;
}

::placeholder {
  color: #e5e5e5;
}

.social {
  margin-top: 30px;
  display: flex;
}

.social div {
  background: red;
  width: 150px;
  border-radius: 3px;
  padding: 5px 10px 10px 5px;
  background-color: rgba(255, 255, 255, 0.27);
  color: #eaf0fb;
  text-align: center;
}

.social div:hover {
  background-color: rgba(255, 255, 255, 0.47);
}

.social .fb {
  margin-left: 25px;
}

.social i {
  margin-right: 4px;
}

/**

ABOUT ME 

**/

.card {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.5);
  max-width: 800px;
  display: flex;
  flex-direction: row;
  border-radius: 25px;
  position: relative;
  width: 100%;
  margin: 0 auto 80px auto;
}

.card h2 {
  margin: 0;
  padding: 0 1rem;
}

.card .title {
  padding: 1rem;
  text-align: right;
  color: #242424;
  font-weight: bold;
  font-size: 12px;
}

.aboutSubtitle {
  font-size: small;
}

.card .desc {
  padding: 0.5rem 1rem;
  font-size: 12px;
}

.card .actions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  padding: 0.5rem 1rem;
}

.img-avatar {
  width: 80px;
  height: 80px;
  position: absolute;
  border-radius: 50%;
  border: 6px solid white;
  background-image: linear-gradient(-60deg, #737272 0%, #000000 100%);
  top: 15px;
  left: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  display: none
}

.aboutLogo {
  filter: invert(100%);
}

.card-text {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.title-total {
  padding: 2.5em 1.5em 1.5em 1.5em;
}

path {
  fill: white;
}

.portada {
  width: 100%;
  height: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-image: url("./images/WilliamGrubeHeadshot.JPG");
  background-position: bottom center;
  background-size: cover;
}

.portada2 {
  background-image: url("./images/logo-no-background.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 24px;
  width: 75%;
}

.portadaWalsh {
  background-image: url("./images/Walsh\ Photo.jpg"), linear-gradient(#0d35ff, white, #0d35ff);
  background-size: contain, cover;
  background-repeat: no-repeat;
  background-position: center;
}

.portadaLogan {
  background-image: url("./images/loganHead.jpg"), linear-gradient(#107ac0, #ead93d);
  background-size: contain, cover;
  background-repeat: no-repeat;
  background-position: center;
}

.aboutBtn {
  border: none;
  background: none;
  font-size: 24px;
  color: #8bc34a;
  cursor: pointer;
  transition: .5s;
}

.autoLink {
  color: black;
  text-decoration: underline;
}

.aboutBtn:hover {
  color: #4CAF50;
  transform: rotate(22deg)
}


@media(max-width: 850px) {

  .backBtn {
    position: relative !important;
    margin-bottom: 16px !important;
  }

  .stats {
    flex-direction: column;
  }

  .satisfactionCardTitle {
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
  }

  .satisfactionCard {
    flex: 1;
    margin: 1em;
    padding: 2em;
    border-radius: 1em;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .percentGraphic {
    width: 50%;
    max-width: 150px;
    margin-top: 1em;
  }

  .card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 450px;
    width: 90%;

  }

  .portada {
    display: none !important;
  }

  .card-text {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .img-avatar {
    display: flex;
    position: relative;
    top: 8;
    left: 0;
  }

  .card .title {
    text-align: center;
  }
}

/**

NAVBAR

**/

.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropbtn {
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  /* adjusted from 160px to 100% */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdownLink {
  width: 100%;
  border: 1px solid white;
  font-size: small;
}

.dropdownLink:hover {
  border: 1px solid black;
}

.dropdown-content a {
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  color: black;
}

.dropdown-content button,
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content button:hover,
.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.topNav {
  width: 100%;
  height: 80px;
  margin-top: 8px;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navOption {
  width: calc(100% / 8);
  /* border-left: 1px solid rgba(255, 255, 255, 0.5); */
  /* border-right: 1px solid rgba(255, 255, 255, 0.5); */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  transition: all 0.5s ease;
  text-align: center;
}

.navLink {
  color: white;
  font-size: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
}

.navOption:hover {
  background-color: white;
}

.navOption:hover .navLink {
  color: black;
}

.selectedNavOption {
  background-color: white !important;
}

.selectedNavOption .navLink {
  color: black !important;
}



.mobileNav {
  display: none;
}


/**
AUTH
**/


.auth {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.95);
  z-index: 10000;
}

.authCard {
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  padding: 32px;
  width: 40%;
  text-align: center;
}

.authCardWords {
  font-size: 20px;
  margin-bottom: 24px;
  color: #333;
  font-weight: 600;
}

.formGroup,
.passwordGroup {
  margin-bottom: 24px;
}

.auth-label {
  font-size: 16px;
  color: #555;
  display: inline-block;
  margin-bottom: 8px;
  text-align: left;
  width: 100%;
}

.inputField {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.auth-btn {
  padding: 14px 0;
  width: 100%;
  background: #242424;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  font-size: 16px;
}

.auth-btn:hover {
  background: #707070;
}

.invalidEmail {
  color: red;
  text-align: center;
  font-size: 16px;
  margin-bottom: 16px;
  margin-top: -24px;
}

#passNoMatch,
#passNoLong,
#passErrorMessage,
#emailErrorMessage {
  color: #ff3333;
  display: none;
}

#passDoMatch {
  color: green;
  display: none;
}

.extraButtons {
  display: flex;
  justify-content: space-between;
}

.auth-extra-btn {
  background: none;
  border: none;
  color: #242424;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
  margin: 0;
  text-decoration: underline;
}

.auth-extra-btn:hover {
  text-decoration: none;
}



.schoolLogos {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  flex-wrap: wrap;
}

.schoolLogo {
  width: 20%;
  padding: 2% 5%;
  text-align: center;
}

.schoolLogo h3 {
  margin-top: 8px;
}

@media(max-width: 850px) {
  .resetButton {
    position: relative !important;
    margin-bottom: 4px !important;
  }

  .aiToolsFormContainer {
    padding-top: 8px !important;
  }

  .buttonContainer {
    justify-content: center !important;
  }

  .schoolLogos {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 0 auto;
    flex-wrap: wrap;
  }

  .schoolLogo {
    width: 50%;
    padding: 2% 5%;
    text-align: center;
  }

  .schoolLogo h3 {
    margin-top: 8px;
  }

  .authCard {
    width: 90%;
  }

  .mobileNav {
    display: block;
  }

  .card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 450px;
    width: 90%;

  }

  .portada {
    display: none !important;
  }

  .card-text {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .img-avatar {
    position: relative;
    top: 8;
    left: 0;
  }

  .card .title {
    text-align: center;
  }

  .topNav {
    display: none;
  }

  .mobileNav {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
  }

  .bars {
    display: flex;
    height: 100%;
    width: 100%;
  }

  #barsBtn {
    height: 100%;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .closeBtn {
    position: absolute;
    top: 10px; /* Adjust as needed */
    right: 20px; /* Adjust as needed */
    border: none;
    background: transparent;
    font-size: 20px; /* Adjust as needed */
    cursor: pointer;
    color: #000000;
}


  .navPopup {
    position: fixed;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    opacity: 8;
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 100000;
  }

  .mobileNavOption {
    height: calc(100% / 14);
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px 0;
  }

  .mobileNavLink {
    color: #000000;
    width: 100%;
    height: 100%;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .mobileNavOption:hover,
  .mobileNavOption:active {
    background-color: #242424;
  }

  .mobileNavOption:hover .mobileNavLink,
  .mobileNavOption:active .mobileNavLink {
    color: white;
  }

  .selectedMobileNavOption {
    background-color: #242424;
  }

  .selectedMobileNavOption .mobileNavLink {
    color: white;
  }

}

/**
DROPDOWN
**/


.dropLi .dropA {
  text-decoration: none;
  color: #242424;
  font-size: 16px;
  width: 100%;
  height: 100%;
}

.dropSpan {
  position: relative;
  /* added this */
  padding: 30px;
  border-radius: 24px;
  background: #242424;
  color: white;
  font-size: 2em;
  cursor: pointer;
  display: block;
  width: 60%;
  margin: 0 auto;
}

.dropSpan::after {
  position: absolute;
  /* use absolute positioning */
  right: 20px;
  /* adjust as needed */
  top: 50%;
  /* center vertically */
  transform: translateY(-50%);
  /* adjust for vertical centering */
  content: "↓";
  display: inline-block;
  /* allow the arrow to be transformed */
  transition: transform 0.5s ease;
}

.dropSpan.active::after {
  transform: translateY(-50%) rotate(180deg);
  /* keep vertical centering and add rotation */
}

.slide {
  clear: both;
  width: 60%;
  /* changed this from 100% to 20% */
  height: 0px;
  overflow: hidden;
  text-align: center;
  transition: height 0.5s ease;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  margin-bottom: 56px;

}

.slide li {
  margin: 1px;
  border-radius: 8px;
  background-color: #cccccc;
  padding: 20px;
  width: 80%;
  cursor: pointer;
}

.slide li:hover {
  margin: 1px;
  border-radius: 8px;
  background-color: #e0e0e0;
  padding: 20px;
  width: 80%;
}

#touch {
  position: absolute;
  opacity: 0;
  height: 0px;
}

#touch:checked+.slide {
  height: auto;
}

@media(max-width: 430px) {
  #touch:checked+.slide {
    height: 900px;
  }
}

.promptButtons {
  margin-top: 10px;
  text-align: center;
}

.newTabBtn {
  margin-top: 0;
}

.promptButton {
  display: inline-block;
  background-color: #cccccc;
  border: 1px solid #ffffff;
  color: #242424;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin: 4px;
  transition: all .5s ease;
  text-align: center;
}

.promptButton:hover {
  background-color: #ffffff;
  border: 1px solid #cccccc;

}

/**
gpt
**/

.chatBtn {
  border: solid 0.5px white;
  background-color: transparent;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  color: white;
}

.app {
  background-color: #343541;
  display: flex;
}

.side-bar {
  background-color: #202123;
  height: 100vh;
  width: 244px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#chatMain {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.info {
  color: white;
  font-size: 11px;
  padding: 10px;
}

.bottom-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inputContainer {
  position: relative;
  width: 100%;
  max-width: 650px;
}

.chatInput {
  color: white;
  width: 100%;
  border: none;
  font-size: 20px;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 12px 15px;
  border-radius: 5px;
}

.chatTitle {
  color: white;
}

.chatInput:focus {
  outline: none;
}

#submit {
  color: white;
  position: absolute;
  bottom: 15px;
  right: 0;
  cursor: pointer;

}

.chatNav {
  border-top: solid 0.5px white;
  padding: 10px;
  margin: 10px;
}

.history {
  padding: 10px;
  margin: 10px;
  height: 100%;
}

.feed {
  overflow: scroll;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.feed li {
  display: flex;
  background-color: #444654;
  width: 100%;
  padding: 20px;
  margin: 20px 0;
}

.feed p {
  color: white;
  font-size: 14px;
  text-align: left;
}

.feed .role {
  min-width: 100px;
}

/**

AI Tools Form

**/

.margin {
  margin: 4px 0;
}

.rateContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}

.rateLabel {
  font-size: medium;
  margin-right: 8px;
}

.step2txt {
  max-width: 85%;
  margin: 0 auto;
}

.aiToolsFormContainer {
  background-color: #242424;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  max-width: 800px;
  margin: 0 24px;
}

.aiToolsFormLabel {
  color: #ffffff;
  font-size: small;
}

.moreBottomMargin {
  margin-bottom: 24px;
}

.aiToolsFormInput,
.aiToolsFormSelect {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.aiToolsFormButton1 {
  padding: 10px 20px;
  background-color: #ffffff;
  border: none;
  color: #242424;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 15px;
  transform: scale(1);
  transition: all .15s ease;
}

.assessmentButton {
  width: 70% !important;
  margin-top: 24px;
}

.aiToolsFormButton1:hover {
  background-color: #f2f2f2;
  box-shadow: 0 0 20px rgba(255, 255, 255, 1);
  transform: scale(1.005);
}

.aiToolsFormButton {
  padding: 15px 30px;
  background: linear-gradient(#fe7570, #f6387d);
  /* You can choose any color */
  border: none;
  color: #ffffff;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1.5em;
  /* Increase font size */
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  /* Box-shadow for 3D effect */
}

/* Hover effect */
.aiToolsFormButton:hover {
  background: linear-gradient(#d12e6a, #da635f);
  /* Darker shade when hovered */
}

.aiToolsForm {
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.aiToolsFormLabel {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
  width: 100%;
  text-align: left;
}

.aiToolsFormInput,
.aiToolsFormSelect {
  background-color: #ffffff;
  color: #242424;
}

.aiToolsFormInput::placeholder {
  color: gray;
}

/* Apply some space between the form button and the rest of the form */
.aiToolsFormButton {
  margin-top: 32px;
  margin-bottom: 16px;

}

.aiToolsFormContent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loadingTxt {
  font-size: large;
  text-align: center;
}

.activityTxt {
  font-size: medium;
  text-align: left;
  width: 100%;
}

.buttonContainer {
  width: 100%;
  max-width: 800px;
  margin: 1px;
  display: flex;
  align-items: center;
  justify-content: end;
}

.backButtonContainer {
  justify-content: start;
}

.resetButton {
  margin: 0;
  position: absolute;
  border: 2px solid darkred;
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  background-color: rgba(255, 0, 0, 0.4);
  cursor: pointer;
}

.resetButton:hover {
  border: 2px solid rgba(255, 0, 0, 0.4);
  background-color: darkred;
}

.backBtn {
  margin: 0;
  position: absolute;
  color: white;
  cursor: pointer;
  top: 8px;
}

.backBtn:hover {
  text-decoration: underline;
}

/**
IDEA LIBRARY
**/
.ideaCard {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 32px;
  font-size: small;
  font-weight: 700;
}

.ideaTitle {
  color: #333;
  font-size: 1.5em;
  text-align: center;
  margin-bottom: 32px;
}

.ideaInfo {
  color: #666;
  font-size: 1em;
  text-align: center;

}

.ideaButtonContainer {
  margin-top: 32px;
}

.ideaButton {
  background-color: #008CBA;
  /* Blue */
  border: none;
  color: white;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border: 1px solid #008CBA;
}

.ideaButton:hover {
  background-color: white;
  color: black;
}

@keyframes flash {
  0% {
    background-color: transparent;
  }

  50% {
    background-color: yellow;
  }

  100% {
    background-color: transparent;
  }
}

.ideaInputContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
}

.ideaInput {
  padding: 10px;
  margin: 0 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 200px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .ideaInputContainer {
    flex-direction: column;
  }

  .ideaInput {
    margin-bottom: 20px;
    width: 80%;
  }
}

.checkboxInput {
  width: 10%;
}

.checkboxLabel {
  font-size: 1.5em;
  text-align: left;
  line-height: 1.5;
  display: block;
  padding-left: 30px;
  /* indent to make room for checkbox */
  width: 90%;
}

.checkBoxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.verifyEmailContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.verifyEmailTxt {
  margin-bottom: 48px;
  font-size: large;
  width: 90%;
  max-width: 600px;
}

.floatingNav {
  max-width: 1000px;
  width: 90%;
  /* fallback in case the viewport is less than 1000px wide */
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  z-index: 999;
  margin-bottom: 24px;
}

.floatingNavContainer {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease;
}

.floatingNavOption {
  color: white;
  font-size: medium;
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease;
  text-align: center;
}

.floatingNavOption:hover {
  color: lightblue;
  background-color: rgba(255, 255, 255, 0.1);
}

.scroll-to-top {
  position: fixed;
  right: 20px;
  top: 20px;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: all 0.3s;
  z-index: 1000;
  background-color: black;
  filter: opacity(.6);
}

.stickyNav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: black;
  z-index: 999;
  max-width: 1000px;
  width: 90%;
  /* fallback in case the viewport is less than 1000px wide */
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  height: 48px;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  z-index: 1000;
}


.scroll-to-section {
  color: white;
  font-size: medium;
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease;
  text-align: center;
}

.scroll-to-section:hover {
  color: lightblue;
  background-color: rgba(255, 255, 255, 0.1);
}

.getStarted {
  position: fixed;
  left: 20px;
  top: 20px;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: all 0.3s;
  z-index: 1000;
  background-color: black;
  filter: opacity(.6);
  width: fit-content;
}

.scroll-to-top:hover {
  filter: opacity(.9);
}

.search-container {
  margin: 1em 0;
  display: flex;
  justify-content: center;
}

.search-input {
  width: 60%;
  height: 35px;
  border-radius: 20px;
  border: none;
  padding: 0 20px;
  font-size: 16px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.4);
  transition: box-shadow 0.2s ease-in-out;
  margin-bottom: 40px;
}

.search-input:focus {
  outline: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
}

.search-input::placeholder {
  color: #717171;
}

.add-idea-btn-container {
  display: flex;
  justify-content: center;
  margin: 2em 0 62px 0;
}

.add-idea-btn {
  padding: 0.5em 2em;
  font-size: 1.25em;
  font-weight: 500;
  color: white;
  text-decoration: none;
  border: none;
  border-radius: 50px;
  /* rounded button */
  background: linear-gradient(90deg, rgba(245, 70, 96, 1) 0%, rgba(239, 104, 74, 1) 100%);
  /* gradient background */
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  /* shadow for 3D effect */
  transition: all 0.3s ease-in-out;
}

.add-idea-btn:hover {
  background: linear-gradient(90deg, rgba(239, 104, 74, 1) 0%, rgba(245, 70, 96, 1) 100%);
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);
  transform: translateY(-7px);
  /* move button up when hovered */
}

/*
SALES PAGE
*/

.sales-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Arial', sans-serif;
  color: #333;
  background-color: #f4f4f4;
  padding: 20px;
}

.sales-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
  padding: 20px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 1000px;
}

.section-title {
  color: #4A4A4A;
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;

}

.section-image {
  width: 15%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

@media(max-width: 975px) {
  .switch-container {
    width: fit-content;
    justify-content: space-between !important;
  }

  .switchLabels {
    font-family: 'Arial', sans-serif;
    font-size: 12px !important;
    color: #555;
    margin: 0 10px;
    width: 100px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    transition: color 0.3s ease;
    text-align: center;
  }

  .section-image {
    width: 25%
  }

  .salesOption {
    margin: 4px;
    background-color: rgba(255, 255, 255, 0.1);
  }

  .salesNav {
    height: 200px;
    width: fit-content;
  }

  .salesNavContainer {
    flex-direction: column;
  }

  .salesOption {
    font-size: small !important;
    padding: 2.5px 7px !important;
    width: 200px;
  }

  .sales-title {
    font-size: 28px !important;
  }
}

@media(max-width: 500px) {
  .section-image {
    width: 35%
  }
}

.section-content {
  text-align: center;
  color: #606060;
  font-size: 16px;
  margin-top: 20px;
}

.cta-button {
  color: #fff;
  background-color: #4A90E2;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin: 10px;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #4281c9;
}

.sessionTitle {
  width: 100%;
  text-align: left;
  font-size: 16px;
  margin: 16px 0 0 0;
}

.session-content {
  text-align: left;
  margin-top: 8px;
}

.section-sub-title {
  font-weight: 500;
  letter-spacing: 1.5px;
  color: #262626;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  background-image: linear-gradient(135deg, #0cb3006d 0%, #ffe600 100%);
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  margin: 20px 0;
  transition: all 0.3s ease;
}

.section-sub-title1 {
  color: black;
  background-image: linear-gradient(135deg, rgba(18, 194, 233, 0.5) 0%, rgba(196, 113, 237, 0.5) 50%, rgba(246, 79, 89, 0.5) 100%);
}

.bulletPoint {
  text-align: left;
}

.bonuses {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.bonus {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #e3e3e3;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #fff;
}

.green {
  color: #28a745;
  font-size: 24px;
  margin-right: 15px;
}

.bonus strong {
  color: #333;
  font-size: 18px;
  display: flex;
  align-items: center;
  height: 30px;
}

.bonusText {
  margin-left: 16px;
  text-align: left;
}

.sales-title {
  margin-top: 16px;
  font-size: 32px;
  color: #242424;
  letter-spacing: 1.2px;
  text-align: center;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 0 16px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0000FF;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked+.slider {
  background-color: #0000FF;
}

input:focus+.slider {
  box-shadow: 0 0 1px #0000FF;
}

input:checked+.slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.trainingContent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.switchLabels {
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  color: #555;
  margin: 0 10px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  transition: color 0.3s ease;
}

.switch-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}

.switch-container[data-checked='individuals'] .switchLabels[data-label='individuals'],
.switch-container[data-checked='schools'] .switchLabels[data-label='schools'] {
  color: #0000FF;
}

.salesNav {
  position: relative;
  margin: 24px 0 0 0;
  background-color: rgba(0, 0, 0, 0.15);
}

.moneybackGuarantee {
  background-image: linear-gradient(135deg, #00FF00 0%, #00FF00 100%);
  font-size: 18px;
  text-align: center;
  letter-spacing: 1px;
  padding: 20px 12px;
  width: fit-content;
  border-radius: 8px;
  margin-top: 8px;
  box-shadow: 0 5px 15px rgba(0, 190, 6, 0.5);
}

.salesOption {
  color: #242424;
}

.salesOption:hover {
  color: #0000FF;
}

.urgent {
  font-weight: 500;
  letter-spacing: 1.5px;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  background-image: linear-gradient(135deg, red 0%, red 100%);
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(255, 0, 0, 1);
  margin: 20px 0;
  transition: all 0.3s ease;
  z-index: 1000000;
}

.urgentLink1 {
  color: white;
  text-decoration: underline;
  cursor: pointer;
  z-index: 222222;
}

.radio-button {
  margin: 10px;
}

.radio-button input[type="radio"] {
  display: none;
}

.radio-button label {
  padding: 10px 20px;
  background-color: #f0f0f0;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
}

.radio-button input[type="radio"]:checked+label {
  background-color: #0000FF;
  color: #fff;
}

.radioButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pricing-details {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}

.pricing-catagory-title {
  font-size: 24px;
  color: #0000FF;
  /* Your intense blue color */
  margin-bottom: 10px;
}

.pricing-list {
  padding-left: 0;
  display: flex;
  flex-direction: column;
}

.pricing-list-option {
  font-size: 18px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.2s ease;
}

.pricing-list-option:hover {
  background-color: #e9ecef;
}

.price-tag {
  text-align: center;
  color: #242424;
  /* White color for contrast */
  padding: 20px;
  border-radius: 50px;
  width: fit-content;
  margin: 0 auto;
  /* Center the price tag */
}

.course-price {
  font-size: 40px;
  /* Larger font size for new price */
  font-weight: bold;
}

.course-price .green {
  font-size: 32px;
}

.enrollBtn {
  display: inline-block;
  background-color: transparent;
  border: 2px solid #0000FF;
  /* Border in your chosen intense blue color */
  color: #0000FF;
  /* Text in your chosen intense blue color */
  text-decoration: none;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.enrollBtn:hover {
  background-color: #0000FF;
  /* Your chosen intense blue color */
  color: #ffffff;
  /* White color for contrast */
}

.signUpBox {
  color: #4A4A4A;
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
}

.urgentLink {
  font-size: 1.5rem;
  text-decoration: none;
}

.testimonialLink {
  text-decoration: underline;
  color: black;
  cursor: pointer;
}

.bigger-image {
  width: 40%;
}

.morePaddingBottom {
  margin-bottom: 24px;
}

.williamTitle {
  font-size: large;
  letter-spacing: 2px;
  background-image: linear-gradient(135deg, #242424 0%, #242424 100%);
  color: white;
  transition: all 0.3s ease;
  border: 1px solid #242424;
}

.williamTitle:hover {
  font-size: large;
  letter-spacing: 2px;
  background-image: linear-gradient(135deg, #f0f0f0 0%, #f0f0f0 100%);
  color: #242424;
  cursor: pointer;
}

.homepage-sections {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 800px;
  margin: 20px 0;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  background: #FFFFFF;
  transition: transform 0.2s ease-in-out;
}

.section:hover {
  transform: scale(1.02);
}

.section-content {
  width: 100%;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  color: inherit;
}

.section-content img {
  width: 100%;
  max-height: 200px;
  object-fit: contain;
  /* This is the updated line */
}

.section-content h3 {
  margin-top: 20px;
  font-size: 24px;
  color: #333;
}

.section-content p {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
}

.mini-navbar {
  width: 100%;
  background: #ffffff;
  /* slightly off-white background for a softer look */
  padding: 15px 0;
  /* increase padding for better alignment */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* add subtle shadow for depth */
}

.mini-navbar ul {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.mini-navbar ul li {
  margin: 0 20px;
  /* increase margin for more spacing */
}

.mini-navbar ul li a {
  width: 190px;
  /* Add fixed width */
  color: #242424;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  padding: 10px 20px;
  /* Increase padding */
  border: 1px solid #242424;
  /* Add a border */
  background-color: #ffffff;
  /* Add a background color */
  border-radius: 5px;
  /* Keep the corners rounded */
}

.mini-navbar ul li a:hover {
  color: #0000FF;
  background-color: #ffffff;
  border-color: #0000FF;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1125px) {
  .mini-navbar ul li {
    margin: 0 15px;
    /* slightly reduce margin */
  }

  .mini-navbar ul li a {
    width: auto;
    /* Adjust width for responsiveness */
    padding: 8px 12px;
    /* reduce padding */
  }
}

/* For mobile devices */
@media (max-width: 995px) {
  .mini-navbar ul {
    flex-direction: column;
    /* stack items vertically */
    align-items: center;
    /* center items */
  }

  .mini-navbar ul li {
    margin: 5px 0;
    /* adjust margin for vertical stacking */
  }

  .mini-navbar ul li a {
    width: auto;
    /* Adjust width for responsiveness */
    padding: 6px 10px;
    /* further reduce padding */
  }
}

.sessionName {
  font-weight: normal;
  font-size: 20px;
}

.sessionDate {
  margin-top: 8px;
  font-size: large;
}

.scrollDown {
  margin-top: 64px;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scrollText {
  font-size: 24px;
}

.scrollIcon {
  margin: 0 16px;
  font-size: 4em;
  animation: bounce 2s infinite;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}


.nav {
  height: 80px;
  background-color: #9a9a9a;
  width: 100%;
}

.sticky {
  position: fixed !important;
  top: 0px;
}

.content {
  background-color: white;
}

.sticky {
  position: fixed !important;
  color: white;
}

.train-logo {
  width: 400px !important;
}

.spaceAround {
  justify-content: space-evenly !important;
}

.schoolImgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.schoolLogo2 {
  filter: invert(0);
  width: 200px;
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}


@media(max-width: 600px) {
  .radioButtonContainer {
    flex-direction: column;
  }
}



.noBoarderBottom{
  border-bottom: none !important;
}

.ag-format-container {
  width: 1142px;
  margin: 0 auto;
}

.ag-courses_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ag-courses_item {
  -ms-flex-preferred-size: calc(33.3333%);
  flex-basis: calc(33.3333%);
  margin: 0 15px 30px;
  overflow: hidden;
  border-radius: 28px;
}

.ag-courses-item_link {
  display: block;
  padding: 30px 20px;
  background-image: linear-gradient(-315deg, #000000 0%, #505050 100%);

  overflow: hidden;

  position: relative;
}

.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date {
  text-decoration: none;
  color: #FFF;
}

.ag-courses-item_link:hover .ag-courses-item_bg {
  -webkit-transform: scale(10);
  -ms-transform: scale(10);
  transform: scale(10);
}

.ag-courses-item_title {
  min-height: 87px;
  margin: 0 0 25px;

  overflow: hidden;

  font-weight: bold;
  font-size: 30px;
  color: #FFF;

  z-index: 2;
  position: relative;
}

.ag-courses-item_date-box {
  font-size: 18px;
  color: #FFF;

  z-index: 2;
  position: relative;
}

.ag-courses-item_date {
  font-weight: bold;
  color: #f9b234;

  -webkit-transition: color .5s ease;
  -o-transition: color .5s ease;
  transition: color .5s ease
}

.ag-courses-item_bg {
  height: 128px;
  width: 128px;
  background-color: #f9b234;

  z-index: 1;
  position: absolute;
  top: -75px;
  right: -75px;

  border-radius: 50%;

  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;
}

.ag-courses_item:nth-child(2n) .ag-courses-item_bg {
  background-color: #3ecd5e;
}

.ag-courses_item:nth-child(2n),
.ag-courses_item:nth-child(3n) {
  margin-top: 16px;
}

.ag-courses_item:nth-child(3n) {
  margin-bottom: 16px;
}

.ag-courses_item1:nth-child(1n) .ag-courses-item_bg {
  background-color: #e44002;
}

.ag-courses_item1:nth-child(2n) .ag-courses-item_bg {
  background-color: #952aff;
}

.ag-courses_item2:nth-child(1n) .ag-courses-item_bg {
  background-color: #cd3e94;
}

.ag-courses_item2:nth-child(2n) .ag-courses-item_bg {
  background-color: #4c49ea;
}

.ag-courses_item2:nth-child(3n) .ag-courses-item_bg {
  background-color: #ea4949;
}



@media only screen and (max-width: 979px) {
  .ag-courses_box {
    justify-content: center;
  }

  .ag-courses_item {
    -ms-flex-preferred-size: calc(50% - 30px);
    flex-basis: calc(50% - 30px);
  }

  .ag-courses-item_title {
    font-size: 24px;
  }

  .ag-format-container {
    width: 96%;
    margin: 0 auto;
    /* Center the container */
  }
}

@media only screen and (max-width: 767px) {
  .ag-courses_box {
    justify-content: center;
  }

  .ag-format-container {
    width: 96%;
    margin: 0 auto;
    /* Center the container */
  }
}

@media only screen and (max-width: 639px) {
  .ag-courses_box {
    justify-content: center;
  }

  .ag-courses_item {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    margin: 0 auto;
    /* Center the item */
  }

  .ag-courses-item_title {
    min-height: 72px;
    line-height: 1;
    font-size: 24px;
  }

  .ag-courses-item_link {
    padding: 22px 40px;
  }

  .ag-courses-item_date-box {
    font-size: 16px;
  }
}

.homeSubTitle {
  margin-top: 64px;
}

.cursorPointer {
  cursor: pointer;
}

.red {
  color: darkred !important;
}

.video {
  width: 30vw;
  /* 90% of the viewport width */
  max-width: 100%;
  /* Ensure it doesn't grow bigger than its container. */
  height: auto;
  /* Maintain the video aspect ratio. */
  margin: 0 auto 64px auto;
  /* Center it horizontally if the parent isn't a flex container. */
}

.dayVideo {
  margin-bottom: 32px;
}

@media(max-width: 1500px) {
  .video {
    width: 40vw;
  }
}

@media(max-width: 1000px) {
  .video {
    width: 50vw;
  }
}

@media(max-width: 700px) {
  .video {
    width: 60vw;
  }
}

@media(max-width: 600px) {
  .video {
    width: 70vw;
  }
}

/* Make sure the poster thumbnail stretches appropriately. */
.video video {
  width: 100%;
  /* This will make the video tag take the full width of its parent, which is 90% of the viewport width */
  height: auto;
}

.disclaimer {
  margin-top: 24px;
}

.tool-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px;
  padding: 24px;
}




.tool-card {
  background-color: #f3f4f6;
  border-radius: 16px;
  padding: 24px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  color: #242424;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border: 1px #242424 solid;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.tool-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
}

.tool-icon img {
  width: 50px;
  height: 50px;
}

.tool-title {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 1.75rem;
  margin: 16px 0;
  color: #0000FF;
}

.tool-text {
  font-size: 1.5rem;
  line-height: 1.5;
  padding: 8px 0;
}

.tool-usage {
  color: #ea4949;
  font-size: 1.35rem;
  padding: 4px 0;
  font-weight: bold;
}

.tool-disclaimer {
  font-size: 1.1rem;
  padding: 4px 0;
  color: #831FD6;
}

.course-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  background-color: #f9f9f9;
  font-family: 'Arial', sans-serif;
}

.course-section {
  margin: 30px 0 80px 0;
  width: 98%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3);
}

.lecture-polls-wrapper {
  display: flex;
  flex-direction: column;
  /* Changed from row to column */
  align-items: center;
  /* Ensures everything is centered */
  gap: 30px;
}

.lecture-part,
.polls-part {
  width: 100%;
  /* Updated so they take up the full width of the container */
}

.lecture-part {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.course-sub-title {
  font-size: 1.5rem;
  /* Bigger than before */
  color: #333;
  /* Darker gray */
  margin-top: 20px;
  /* Extra spacing */
  text-align: center;
  /* Centered text */
  font-weight: 600;
  /* Semi-bold */
}


.course-section-title {
  background-color: #0000FF;
  color: white;
  font-size: 32px;
  padding: 15px;
  text-align: center;
  margin: 0;
  width: 100%;
  border-radius: 8px;
  letter-spacing: 4px;
  font-weight: 100;
}

.course-sub-title {
  font-size: 32px;
  font-weight: 100;
  letter-spacing: 4px;
  color: black;
  margin-top: 10px;
  border-bottom: 1px solid #24242431;
}

.course-content {
  background-color: #fff;
  padding: 20px;
  text-align: center;
  line-height: 1.6;
}

.course-button-container {
  display: flex;
  justify-content: start;
}

.poll-submit-button+.poll-edit-button {
  margin-left: 8px;
}


.course-video-content {
  width: 35vw;
  /* 90% of the viewport width */
  max-width: 100%;
  /* Ensure it doesn't grow bigger than its container. */
  height: auto;
  /* Maintain the video aspect ratio. */
  margin: 0 auto 8px auto;
  /* Center it horizontally if the parent isn't a flex container. */
  margin-top: 16px;
}

.poll-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  
}

.poll-item input[type="text"] {
  width: 70%;
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.poll-item button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.poll-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  background-color: #f8f8f8;
}


.poll-form {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: left;
  width: 100%;
}

.poll-input {
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  font-size: 18px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.poll-submit-button,
.poll-edit-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  margin: 5px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  letter-spacing: 1px;
}

.poll-submit-button:disabled {
  background-color: #ccc;
}

.poll-edit-button {
  background-color: #FFA500;
  /* Orange */
}

.poll-label {
  font-size: 20px;
  font-weight: bold;
  color: black;
  margin-bottom: 10px;
  letter-spacing: 1px;
}

.poll-question {
  font-size: 20px;
  text-align: left;
  margin-bottom: 8px;
}

.breakout-directions{
  font-size: 18px; /* Slightly larger */
  font-weight: 700; /* Bolder */
  letter-spacing: 1px;
  color: #555555; /* Dark color for prominence */
  margin-bottom: 12px; /* More space below the directions */
}

.textToCopy {
  position: relative; /* Needed for absolute positioning of the copy icon/text */
  border: 1px solid #e6e6e6; /* Lighter border */
  background-color: #f8f8f8; /* Lighter background */
  color: #595959; /* Lighter text color */
  font-size: 14px; /* Smaller font size */
  padding: 8px;
  padding-right: 40px; /* Extra padding to accommodate the copy icon/text */
  margin-top: 5px;
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 3px;
  width: calc(100% - 32px); /* Subtracting 16px from each side */
  box-sizing: border-box; /* Include padding and borders in the element's total width and height */
  overflow-x: auto;
  cursor: pointer; /* Indicates the text can be clicked */
  text-align: left;
}

.copy-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #6c757d; /* A neutral color */
  font-size: 18px;
  transition: color 0.3s;
  user-select: none; /* Prevent icon/text from being selected */
}

.textToCopy:hover .copy-icon {
  color: #4CAF50; /* Green color on hover to indicate copy action */
}

.copied {
  color: #34a853; /* Color changes when copied */
  font-weight: bold;
  /* No need for display, padding, or margin as this class is for icon/text */
}

.activity-description {
  font-size: 18px;
  font-weight: 100;
  font-style: italic;
  color: #242424;
}

.poll-item.poll-card {
  position: relative;
}

.view-results-button {
  position: absolute;
  top: 0;
  right: 0;
  text-decoration: underline;
  cursor: pointer;
  border: none;
  background: none;
  color: #242424;
  font-size: 16px;
  margin: 4px 4px 0 0;
}

.view-results-button:hover {
  color: blue;
}

/* Activity title */
.activity-title {
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 3px;
  color: #333;
  margin-top: 15px;
  text-align: center;
}

/* Activity content */
.course-activity-content {
  border-top: 1px solid #ccc;
  background-color: #f9f9f9;
  /* Lighter gray to contrast with course-content */
  padding: 20px;
  text-align: center;
  line-height: 1.6;
  border-radius: 4px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.course-activity-content:first-child {
  border-top: none;
}

.activities-wrapper {
  counter-reset: activity;
  /* Initialize the counter */
}

.activity-number::before {
  counter-increment: activity;
  /* Increment the counter */
  content: counter(activity);
  /* Add the counter before the title */
  background: #0000FF;
  color: white;
  /* Text color */
  border-radius: 50%;
  /* Rounded shape */
  width: 42px;
  /* Width */
  height: 42px;
  /* Height */
  display: inline-flex;
  /* Make it an inline-flex container */
  align-items: center;
  /* Center items vertically */
  justify-content: center;
  /* Center items horizontally */
  margin-right: 10px;
  /* Space between the number and the title */
  font-weight: bold;
  /* Bold text */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* A little shadow for depth */
}

.activity-form {
  counter-reset: question;
  /* Initialize the question counter */
}

.question-number::before {
  counter-increment: question;
  /* Increment the counter */
  content: counter(question) ". ";
  /* Add the counter before the question */
  color: #555555;
  /* Color */
  font-weight: bold;
  /* Bold */
  font-size: 1.2em;
  /* Font size */
}



/* Activity form */
.activity-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Aligned to the start */
  width: 100%;
}

.activity-input-holder {
  width: 100%;
}

/* Activity input */
.activity-input {
  width: 100%;
  padding: 15px;
  margin: 8px 0;
  border: 2px solid #ccc;
  border-radius: 10px;
  font-size: 18px;
  height: 150px;
  outline: none;
  transition: border-color 0.3s ease;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  resize: vertical;
  /* allows vertical resize, disables horizontal resize */
}

.activity-input:focus {
  border-color: #0000FF;
  /* You can choose a color that suits your theme */
  box-shadow: 0px 3px 6px rgba(0, 123, 255, 0.2);
}

.activity-input:disabled {
  background-color: #f1f1f1;
  cursor: not-allowed;
}

/* For Firefox */
.activity-input::-moz-placeholder {
  color: #888;
  font-style: italic;
  font-weight: lighter;
}

/* For WebKit browsers like Safari and Chrome */
.activity-input::-webkit-input-placeholder {
  color: #888;
  font-style: italic;
  font-weight: lighter;
}

/* For Internet Explorer */
.activity-input:-ms-input-placeholder {
  color: #888;
  font-style: italic;
  font-weight: lighter;
}

/* For Microsoft Edge */
.activity-input::-ms-input-placeholder {
  color: #888;
  font-style: italic;
  font-weight: lighter;
}

/* Standard, future-proof way (some browsers, including Firefox) */
.activity-input::placeholder {
  color: #888;
  font-style: italic;
  font-weight: lighter;
}


/* Activity question container */
.activity-question-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Aligned to the start */
  width: 100%;
  margin-bottom: 15px;
}

/* Activity submit button */
.activity-submit-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  margin: 5px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  letter-spacing: 1px;
}

/* Course links container */
.course-links-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
  width: fit-content;
}

.course-links-wrapper {
  display: flex;
  align-items: center; /* aligns the items to the left */
  padding: 16px;
  background-color: #f2f2f2;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  margin-right: auto;  /* pushes the box to the left */
}


.links-title {
  margin-right: 20px;
  font-size: 1.5em;
  font-weight: 600;
  color: #333;
  line-height: 1;
  padding: 8px;
  border-bottom: 3px solid #0000FF;
}

.links-section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%; /* Vertically aligns the title and icons */
  margin: 24px 0;
}

.course-links-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.course-link-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 12px; /* space between icons */
  border-radius: 40%;
  padding: 8px;
  background: rgba(0, 0, 255, 0.1);
  transition: background 0.3s ease;
}

.link-icon {
  width: 32px; /* icon size */
  height: 32px; /* icon size */
}

.link-label {
  font-size: 12px;
  text-align: center;
  margin-top: 4px;
}


.course-link-icon:hover {
  background: rgba(0, 0, 255, 0.2);
}

.activity-nav-bar {
  display: flex;
  flex-wrap: wrap;  /* Prevents wrapping onto the next line */
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  border-radius: 10px;
  padding: 20px 0 10px 0;
}

.activity-nav-item {
  margin: 6px;
  padding: 8px 16px;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: white;
  font-size: 12px;
}

.activity-nav-item:last-child {
  margin-right: 0;  /* Removes the margin on the last element */
}

.activity-nav-item:hover,
.activity-nav-item:focus {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.activity-nav-item-completed {
  background-color: #28a745;
}

.activity-nav-item-incomplete {
  background-color: #ff4500;
}



.course-completion-section {
  padding: 30px;
  background: linear-gradient(45deg, #f3f3f3, #e7e7e7);
  text-align: center;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  margin-top: 40px;
  width: 95%;
}

.course-completion-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.completion-intro {
  font-size: 18px;
  margin-bottom: 20px;
  color: #666;
}

.completion-list {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.completion-item {
  flex-basis: calc(50% - 20px);
  margin: 10px;
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.completion-label {
  font-weight: 600;
  font-size: 16px;
}

.completion-status, .go-to-section {
  padding: 5px 15px;
  border-radius: 12px;
  font-size: 14px !important;
}

.completed {
  background-color: #28a745;
  color: white;
}

.pending {
  background-color: #ffc107;
  color: white;
}

.completion-buttons {
  margin-top: 30px;
}

.next-day-button, .contact-button {
  padding: 15px 30px;
  margin: 10px;
  background: linear-gradient(45deg, #007bff, #0056b3);
  color: white;
  border: none;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  cursor: pointer;
  font-size: 14px;
}

.next-day-button:hover, .contact-button:hover {
  background: linear-gradient(45deg, #0056b3, #003f8a);
}

.go-to-section {
  padding: 10px 20px;
  margin-left: 10px;
  background: linear-gradient(45deg, #ff6347, #ff4500);
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 0.9rem;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

.go-to-section:hover {
  background: linear-gradient(45deg, #ff4500, #ff2c00);
  box-shadow: 0 6px 8px rgba(0,0,0,0.2);
}

.course-navbar {
  display: flex;
  justify-content: space-evenly;
  background: #f9f9f9;
  padding: 12px;
  border-radius: 20px;
  box-shadow: 0px 5px 20px rgba(0,0,0,0.1);
}

.course-nav-item {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column;
  text-decoration: none;
  color: #333;
  font-size: 16px;
  padding: 12px 24px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 2px 10px rgba(0,0,0,0.05);
  margin: 0 4px;
  text-align: center;
  cursor: pointer;
}



.course-nav-item:hover {
  background: rgba(0, 0, 0, 0.1);
  transform: translateY(-3px);
  box-shadow: 0px 4px 20px rgba(0,0,0,0.1);
}

.course-nav-item {
  display: inline-block;
  margin-right: 20px;
}

.status-label {
  display: block;
  text-align: center;
  border-radius: 12px;
  padding: 2px 8px;
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: 4px;
  font-size: 10px;
}

.nav-completed {
  color: #28a745;
}

.nav-pending {
  color: #ff0000;
}

/* Common button styles */
.hidden-menu-button,
.show-menu-button {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000; /* Ensures it stays above other elements */
}

/* Button is hidden */
.hidden-menu-button {
  display: none;
}

/* Button is shown */
.show-menu-button {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #333;
  color: #fff;
  text-align: center;
  line-height: 50px;
  animation: circleGrow 0.3s ease-in-out; /* Animation for appearing */
}

/* Animation for circle button */
@keyframes circleGrow {
  0% {
    width: 0;
    height: 0;
    line-height: 0;
  }
  100% {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}

/* Common menu styles */
.hidden-menu-options,
.show-menu-options {
  display: none;
  position: fixed;
  top: 0;
  right: 50px;
  z-index: 999;
}

/* Menu options are shown */
.show-menu-options {
  display: block;
  background-color: #333; /* Background color */
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.1); /* Shadow for depth */
  width: 200px;
  text-align: left;
  animation: slideInRight 0.3s ease-in-out; /* Animation for sliding in */
}

/* Menu option links */
.show-menu-options a {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  color: white;
  text-decoration: none;
  font-weight: bold;
}

/* Hover effect for links */
.show-menu-options a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Icon for links */
.icon {
  margin-right: 8px;
  font-size: 18px;
}

/* Animation for sliding menu */
@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}



@media (max-width: 768px) {

  .next-day-button{
    margin-top: 24px !important;
  }
  .completion-item{
    width: 95% !important;
  }
  .completion-label{
    font-size: 12px;
  }

  .completion-status, .go-to-section{
    margin-left: 16px;
    font-size: 12px !important;
  }

  .course-navbar{
    flex-direction: column;
    width: 90%;
  }

  .course-nav-item {
    flex: 1 0 calc(50% - 16px); /* Flex Grow, Flex Shrink, Flex Basis */
    padding: 8px 16px;
    text-align: center;
    margin: 4px 0 ;
  }
  .lecture-polls-wrapper {
    flex-direction: column;
    gap: 15px;
  }

  .lecture-part,
  .polls-part {
    width: 100%;
    /* Take up the full width on smaller screens */
  }

  .course-section {
    width: 90%;
    /* Increase the width for mobile screens for better use of space */
  }

  .course-video-content {
    width: 70vw !important;
    /* Adjust the video width for mobile screens */
    margin-bottom: 16px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .lecture-part,
  .polls-part {
    width: 50%;
    /* Adjust this based on how you want it to look on an iPad */
  }

  .course-section {
    width: 85%;
    /* Slightly increase the width for iPad screens */
  }

  .course-video-content {
    width: 50vw;
    /* Adjust the video width for iPad screens */
    margin-bottom: 16px;
  }
}

.noPaddingTop {
  padding-top: 0 !important;
}

.loader-wrapper {
  display: flex;
  flex-direction: column; /* Changed to column to stack elements vertically */
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.loader {
  position: relative;
  width: 100px;
  height: 100px;
  margin-bottom: 16px; /* Added some bottom margin to separate it from the text below */
}

.spinner {
  position: absolute;
  border: 8px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top: 8px solid #242424;
  width: 100%;
  height: 100%;
  animation: spin 1s linear infinite;
}

.loading-text,
.additional-text { /* Added .additional-text to the existing style */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  color: #242424;
}

.loading-text {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: fade 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}


.poll-result-container {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.poll-result-container:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.poll-result-header {
  font-size: 26px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 12px;
}

.poll-result-question {
  font-size: 22px;
  font-style: italic;
  color: #424242;
  margin-bottom: 20px;
}

.poll-result-list {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
}

.poll-result-item {
  background-color: #fafafa;
  border: 1px solid #e0e0e0;
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 16px;
  width: calc(25% - 16px);
  font-size: 16px;  /* Making text larger */
  transition: all 0.3s ease-in-out;
  text-align: center;
  cursor: pointer;
}

.poll-result-item:hover {
  background-color: #dddddd;
  border-color: #959595;
}

@media (max-width: 768px) {
  .poll-result-item {
    width: calc(50% - 16px);
  }
}

.poll-result-school {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.poll-result-school-name {
  font-size: 30px;
  font-weight: 700;
  color: #1a1a1a;
}

.poll-result-school-pic {
  width: 80px;
  height: auto;
  object-fit: cover;
  margin-right: 28px;
}

.poll-result-item.clicked {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: #ffffff;
  border: 3px solid #3faffa;
  width: auto;
  padding: 20px;
  font-size: 20px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
}

.poll-result-header, .poll-result-question {
  font-size: 28px;  /* Slightly larger text */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  cursor: pointer;
  background: none;
  border: none;
  color: #333;
}

.close-button:hover {
  color: #000000;
}


.resource-completed {
  border: 2px solid #28a745 !important;
}

.resource-incomplete {
  border: 2px solid #ff0000 !important;
}

.resource-inprogress {
  border: 2px solid #ffa500 !important;
}

.resource-status {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 8px;
  text-align: center;
  color: inherit; /* This will be dynamically set */
}

/* General List and Container Styles */
.admin-result-list,
.admin-report-item-list {
  display: flex;
  flex-direction: column;
}

/* General Button Styling */
.admin-report-toggle-button,
.show-details-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.admin-report-toggle-button:hover,
.show-details-button:hover {
  background-color: #45a049;
}

/* Complete / Incomplete Styles */
.admin-report-complete,
.admin-report-incomplete {
  display: inline-block;
  padding: 5px 15px;
  border-radius: 5px;
  margin: 5px;
  font-weight: bold;
}

.admin-report-complete {
  background-color: #28a745;
  color: white;
}



/* Participant and Section Containers */
.admin-report-participant-container {
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #f1f1f1;
  padding: 15px;
  transition: background-color 0.3s ease-in-out;
}

.admin-report-participant-container:hover,
.admin-report-participant-container.active {
  background-color: #f9f9f9;
}

.admin-report-section {
  margin-bottom: 20px;
}

/* Headings */
.admin-report-section-title {
  font-size: 18px;
  margin-bottom: 10px;
}

/* Collapsible Content */
.collapsible-content {
  overflow: hidden;
  overflow: hidden;
}

.collapsible-content.show {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 10px;
  padding: 15px;
}

/* Add to your existing CSS */
.admin-report-participant-details {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.admin-report-participant-details span {
  font-size: 14px;
  font-weight: bold;
}

.admin-report-participant-day-info{
  display: flex;
  justify-content: space-around;
  margin: 4px 0;
}

.admin-report-participant-day-info span {
  margin-right: 10px;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  font-size: 14px;
}

.admin-report-day-complete {
  background-color: #28a745;
  color: white;

}

.admin-report-day-incomplete {
  background-color: #dc3545;
  color: white;
}

.admin-report-day-pending {
  background-color: #ff9500;
  color: white;

}

.show-details-button {
  background-color: #0000FF;
}

.show-details-button.active,
.show-details-button:hover {
  background-color: #000099;
}

/* Card Container */
.admin-report-participant-card {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  margin-bottom: 20px;
  padding: 15px;
  transition: box-shadow 0.3s ease-in-out;
}

.admin-report-participant-card:hover {
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

/* Make details stand out */
.admin-report-participant-details {
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

/* Button */
.show-details-button {
  background-color: #0000FF;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

.show-details-button.active,
.show-details-button:hover {
  background-color: #000099;
}

/* Day Container */
.admin-report-day-container {
  margin-bottom: 20px;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.admin-report-day-title {
  font-size: 24px;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

/* Report Sections */
.admin-report-section {
  margin-bottom: 15px;
}

/* Item List */
.admin-report-item-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.admin-report-item-list div {
  background-color: #f1f1f1;
  padding: 5px 10px;
  border-radius: 4px;
  margin-bottom: 5px;
  flex: 1;
}

.admin-report-field-container {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f9f9f9;
}

.admin-report-field-inner-container {
  padding-left: 10px;
}

.admin-report-field-title {
  font-size: 12px;
  margin-bottom: 5px;
  color: #333;
}

.admin-report-field-detail {
  font-size: 16px;
}

.admin-report-field-status-complete {
  color: green;
  font-weight: bold;
}

.admin-report-field-status-incomplete {
  color: red;
  font-weight: bold;
}

.center{
  text-align: center;
}

.largeIcon{
  font-size: 24px;
}

.moreMarginTop{
  margin-top: 64px;
}

.underline{
  text-decoration: underline;
  color: #000000;
  font-size: 18px;
}

.badgePopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background-color: #f9f9f9;  /* Light grey background */
  padding: 40px;  /* Increased padding */
  border: 3px solid #ccc;  /* Light grey border */
  border-radius: 15px;  /* Rounded corners */
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);  /* Box shadow for 3D effect */
  width: 350px;  /* Fixed width */
  height: 475px;  /* Fixed height */
  z-index: 10000;
}

.badgePopup p{
  margin-top: 16px;
 text-align: center;
 font-size: 12px;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  padding: 10px;
  font-size: 24px;  /* Larger font size */
  font-weight: bold;  /* Bold text */
  background-color: #dddddd;  /* Background color */
  border-radius: 50%;  /* Rounded button */
}

.badgeImgPopup {
  width: 100%;
  height: auto;  /* Maintain aspect ratio */
  border-radius: 10px;  /* Rounded corners for the image */
}

.lessPaddingTop{
  padding-top: 24px !important;
}

.noMarginBottom{
  margin-bottom: 0;
}

/* Container for the whole section */
.super-secret-container {
  width: 100%;
  padding: 20px;
}

/* Each Day's Section */
.super-secret-day-section {
  margin-bottom: 30px;
}

/* Title for each day */
.super-secret-day-title {
  font-size: 24px;
  margin-bottom: 10px;
}

/* The list of completers */
.super-secret-completers-list {
  display: flex;
  flex-direction: column;
}

/* Each completer row */
.super-secret-completer-row {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

/* Name in each row */
.super-secret-completer-name {
  flex: 1;
  padding: 0 10px;
}

/* Email in each row */
.super-secret-completer-email {
  flex: 1;
  padding: 0 10px;
}

/* School in each row */
.super-secret-completer-school {
  flex: 1;
  padding: 0 10px;
}

/* No completers text */
.super-secret-no-completers {
  padding: 10px;
  color: #999;
}

.resetPasswordContainer{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 50px;
}

.resetPasswordCard {
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  padding: 32px;
  width: 90%;
  text-align: center;
  height: auto;
}

.success-message, .error-message {
  font-size: 24px;
  color: green;
  margin-bottom: 1em;
  font-weight: bold;
}

.error-message{
  color: red;
}

.training-link {
  display: inline-block;
  background-color: #242424;
  font-size: 16px;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}

.training-link:hover{
  transform: scale(1.03);
}

.redBackground{
  background-color: red;
}

.blackIcon {
  color: black !important;
  font-size: 32px;
}

.breakout-title{
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 5rem !important;
}

.breakout-title-img{
  width: 20%;
  margin: 24px 0 48px 0;
}
/* 

APP 

*/
/* Base styles */
.app-container {
  font-family: 'Arial', sans-serif;
  color: #242424;
  background-color: white;
}

/* Header styles */
/* Header styles */
.app-header {
  background-color: #242424;
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed; /* Make header fixed */
  width: 100%; /* Full width */
  top: 0;
  left: 0;
  z-index: 1000; /* Ensure it's above other elements */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.app-header h2 {
  font-family: 'Lato', sans-serif;
  color: white;
  font-size: 2em; /* Adjust size to fit your design */
  font-weight: 300; /* Lighter weight for a modern look */
  letter-spacing: 2px;
  margin: 0; /* Reset default margin */
  padding: 0 20px; /* Add padding for spacing */
  display: flex; /* To align it properly with other elements */
  align-items: center; /* Vertical alignment */
  justify-content: center; /* Horizontal alignment */
  flex-grow: 1; /* Allows it to take up available space */
}


.app-logo {
  font-size: 1.5em;
  font-weight: bold;
}

.app-main-logo {
  height: 50px; /* adjust as needed */
  filter: invert(100%); /* invert colors for the logo */
}

.app-logout-btn {
  background-color: white;
  color: #242424;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.app-logout-btn:hover {
  background-color: #f0f0f0; /* a slightly different shade for hover effect */
}

.app-section {
  margin-bottom: 20px;
}

.app-section-title {
  font-size: 1.4em;
  margin-bottom: 15px;
}



/* SIDEBAR */

.app-sidebar {
  background-color: #f0f0f0;
  width: 200px;
  position: fixed;
  top: 70px;
  bottom: 0;
  left: 0;
  padding: 20px 20px 0 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Adds scrolling for long lists */
}

.app-nav {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.app-nav-section {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
}

.app-nav-item {
  margin-bottom: 15px; /* Increased spacing */
  display: flex;
  align-items: center;
  width: 100%;
  font-size: small;
  font-weight: 400;
}

.app-nav-item a {
  color: #242424;
  text-decoration: none;
  display: block;
  padding: 8px 12px;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.app-nav-item a:hover {
  background-color: #e0e0e0;
  color: #666666;
  text-decoration: none;
}

/* Subtle underline animation on hover */
.app-nav-item a::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #666666;
  transition: width 0.3s;
}

.app-nav-item a:hover::after {
  width: 100%;
}

/* MAIN */
.app-main {
  margin-left: 220px;
  padding: 20px;
  padding-top: 90px;
  position: relative;
  z-index: 500;
  background-color: #fff; /* Optional: for a cleaner look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
}

/* Search section styling */
.app-search-section {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 30px; /* Increased for more space */
  background-color: #f8f8f8; /* Light background for the search area */
  padding: 10px;
  border-radius: 8px;
}

/* Styling for the search bar */
.app-search-bar {
  width: 70%;
  padding: 10px;
  border: 2px solid #ccc; /* Slightly thicker border for emphasis */
  border-radius: 6px; /* Softer border radius */
  font-size: 1em; /* Adjust font size as needed */
}

.app-search-bar:focus {
  outline: none;
  border: 1px solid #0000FF;
  box-shadow: 0 0 4px #0000FF;
}


.app-title {
  color: #242424;
  font-size: 3em; /* Large size for impact */
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  text-shadow: .1px .1px 0px rgba(0, 0, 0, 0.0001); /* Subtle shadow for depth */
  margin-left: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 1.5px; /* Wider letter spacing for a modern look */
  background: linear-gradient(45deg, #242424, #242424); /* Monochromatic gradient */
  -webkit-background-clip: text;
  color: transparent; /* Make text transparent to show the background */
  background-clip: text;
  filter: drop-shadow(2px 2px 2px #242424); /* Drop shadow for depth */
}


/* Tool tabs styling */
.app-tool-tabs {
  display: flex;
  margin-bottom: 30px;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Adding shadow for depth */
  overflow: hidden; /* Ensures rounded corners for inner elements */
}

.app-tab-btn {
  padding: 10px 20px;
  border-radius: 6px;
  background-color: #e0e0e0;
  margin-right: 10px;
  font-size: 12px;
  font-weight: 500;
  transition: all 0.3s ease;
  border: none; /* Removing border for a cleaner look */
  cursor: pointer;
  position: relative; /* Needed for the hover effect */
  overflow: hidden;
}

.app-tab-btn:hover::before {
  transform: scale(1.5);
  opacity: 0;
}

.app-tab-btn::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background-color: #0000FF;
  transition: all 0.3s;
  border-radius: 50%;
  transform: scale(0);
  opacity: 1;
  z-index: 0;
  transition: all 0.5s;
}

.app-tab-btn:hover, .app-tab-btn:focus {
  background-color: #d0d0d0;
  color: #242424;
  transform: translateY(-2px); /* Slight raise effect on hover */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Shadow effect for depth */
}

.app-tab-btn::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: #0000FF; /* A pop of color */
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.app-tab-btn:hover::after, .app-tab-btn:focus::after {
  transform: scaleX(1); /* Line grows on hover */
}


/* Tool list styling */
.app-tool-list {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap as needed */
  justify-content: space-between; /* Distributes space between and around items */
  gap: 20px; /* Space between items */
  height: auto;
}

.app-tool-name {
  flex-basis: calc(33.333% - 20px); /* Three items per row, accounting for gap */
  margin-bottom: 20px;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  text-decoration: none;
  color: #242424;
  font-weight: 500;
  font-size: medium;
  letter-spacing: 1px;
  display: flex; /* For internal alignment */
  align-items: center; /* Centers content vertically */
  justify-content: center; /* Centers content horizontally */
  text-align: center; /* Centers text */
  overflow: hidden;
  position: relative;
  flex-direction: column; 
}

.app-tool-name::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, transparent, rgba(0, 0, 255, 0.1), transparent);
  transition: all 0.7s;
}

.app-tool-name:hover::after {
  left: 100%;
}

.app-tool-name:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.tool-logo {
  width: 20%; /* Adjust logo size as needed */
  height: auto;
  margin-bottom: 15px; /* Space between logo and text */
}

.tool-details h4 {
  margin: 0;
  color: #242424;
  font-size: 1.2em; /* Adjust title size */
}

.tool-details p {
  font-size: 0.9em; /* Adjust description size */
  color: #606060; /* Subtle color for description */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .app-tool-name {
    flex-basis: 100%; /* Full width for smaller screens */
  }
}

/* APP SIGN IN */

/* Auth Modal Backdrop */
.app-auth-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #242424;
  z-index: 2000;
}

/* Auth Modal Content Box */
.app-auth-modal-content {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: auto;
  max-width: 90%;
}

/* Branding Section */
.app-auth-branding {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.app-auth-branding h2 {
  font-weight: 400;
  font-size: 1.5em;
  margin-top: 20px;
  text-align: center;
}

.app-auth-logo {
  max-width: 100px;
  height: auto;
  margin-bottom: 10px;
}

/* Error Message */
.app-auth-error-message {
  color: #ff0033;
  background-color: #ffe6e6;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
}

/* Form and Inputs */
.app-auth-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.app-auth-input {
  padding: 12px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.app-auth-input:focus {
  border-color: #0000FF;
}

.app-auth-toggle, .app-auth-google{
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Buttons */
.app-auth-submit,
.app-auth-toggle button,
.app-auth-google button,
.app-auth-close button {
  padding: 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;

  transition: background-color 0.3s, color 0.3s, transform 0.2s, box-shadow 0.2s ease-in-out;
}

.app-auth-submit {
  background-color: #0000FF;
  color: white;
  border: none;
}

.app-auth-submit:hover {
  background-color: #000099;
}

/* Link-like buttons */
.app-auth-toggle button,
.app-auth-google button,
.app-auth-close button {
  background: none;
  border: none;
  color: #0000FF;
}

.app-auth-google button:hover,
.app-auth-close button:hover {
  color: #000099;
  transform: scale(1.05);
}

.app-auth-toggle button:hover{
  text-decoration: underline;
}

/* Google Sign-in Specific Styles */
.app-auth-google {
  justify-content: center;
}

.app-auth-google button {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  color: #5f6368;
  padding: 10px 15px;
  border: 1px solid #d6d6d6;
  font-weight: 500;
  letter-spacing: 0.25px;
}

.app-auth-google-logo {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.app-auth-google button:hover,
.app-auth-google button:focus {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .app-auth-modal-content {
    width: 90%;
  }
}

/*

ONE-STEP-TOOL

*/

/* Lesson Plan Generator Enhanced Styles */

.app-tool-container {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 650px;
  margin: 30px auto;
  transition: box-shadow 0.3s ease;
}

.app-tool-container:hover {
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

.app-tool-heading {
  color: #333;
  font-size: 1.8em;
  margin-bottom: 25px;
  text-align: center;
}

.app-tool-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.app-tool-label {
  color: #555;
  font-weight: 600;
  margin-bottom: 5px;
}

.app-tool-input, .app-tool-select {
  padding: 10px 15px;
  border: 2px solid #e6e6e6;
  border-radius: 5px;
  font-size: 1em;
  color: #333;
  transition: border-color 0.3s ease;
}

.app-tool-input:focus, .app-tool-select:focus {
  outline: none;
  border-color: #a0a0a0;
  box-shadow: 0 0 5px rgba(160, 160, 160, 0.5);
}

.app-tool-btn {
  background-color: #4CAF50; /* Green for go */
  color: white;
  padding: 12px 20px;
  font-size: 1em;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.app-tool-btn:hover {
  background-color: #45a049;
  transform: translateY(-2px);
}

.app-tool-response {
  margin-top: 30px;
  padding: 20px;
  background-color: #f7f7f7;
  border: 2px solid #e6e6e6;
  border-radius: 5px;
  color: #333;
  overflow-y: auto;
  transition: border-color 0.3s ease;
  height: auto;
}

.app-tool-response:hover {
  border-color: #a0a0a0;
}


@media screen and (max-width: 475px) {
  .ndasaLogo, .collab {
    display: none !important;
  }
}

.copy-notification {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px 25px;
  background-color: #71BC68; /* Soft grey background */
  color: white; /* Dark grey text for better readability */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  text-align: center;
  z-index: 1000;
  font-size: 14px; /* Smaller font size */
  font-weight: normal; /* Regular font weight */
  animation: fadeInOut 2.5s ease-in-out;
  opacity: 0; /* Start with an invisible state */
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0; }
  25%, 75% { opacity: 1; }
}

.tesimonials-floating-btn {
  position: fixed;
  right: 20px;
  top: 20px;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: all 0.3s;
  z-index: 1000;
  background-color: red;
  filter: opacity(.6);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, box-shadow 0.3s;
}

.tesimonials-floating-btn:hover {
  background-color: darkred;  /* A darker shade of red-orange */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.tesimonials-floating-btn:focus {
  outline: none;  /* Removes the outline to keep the UI clean */
}

@keyframes pulse {
  0% {
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);  /* Starting with red with transparency */
  }
  70% {
      box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);  /* Expands and fades */
  }
  100% {
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);  /* Fades completely */
  }
}

@keyframes greenpulse {
  0% {
      box-shadow: 0 0 0 0 rgba(0, 93, 65, 0.7);  /* Starting with green (#005d41) with transparency */
  }
  70% {
      box-shadow: 0 0 0 10px rgba(0, 93, 65, 0);  /* Expands and fades */
  }
  100% {
      box-shadow: 0 0 0 0 rgba(0, 93, 65, 0);  /* Fades completely */
  }
}


.tesimonials-floating-btn {
  animation: pulse 2s infinite;
}

.blue{
  color: #4c49ea;
}

.vibrent-green{
  color: #3ecd5e;
}

.testimonial-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.testimonial-loader-container p{
  font-size: 12px;
  margin-top: 8px;
}

.testimonial-loader {
  border: 8px solid #242424; /* Light grey background */
  border-top: 8px solid #38794B; /* Green color */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin .5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* LEAD MAGNET */
.ai-resource-center-steps-container {
  width: 90%;
  max-width: 1600px;
  margin: 0px auto 80px;
  padding: 0px 30px;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  position: relative; /* Add this */
}

.ai-resource-center-main-title {
  font-size: 2.75rem;
  color: #333;
  text-align: center;
  margin-bottom: 35px;
  font-weight: 700;
}

.ai-resource-center-steps-nav-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 35px;
  padding: 18px 30px; /* Increase horizontal padding */
  background-color: #f3f4f6;
  border-radius: 12px 12px 0 0; /* Match top corners with container */
  position: absolute; /* Add this */
  left: 0; /* Add this */
  right: 0; /* Add this */
  top: 0; /* Add this */
}

.ai-resource-center-content {
  padding-top: 80px; /* Adjust based on the height of your nav bar */
}

.ai-resource-center-step-nav-item {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 12px;
  text-decoration: none;
  color: #fff;
  background-color: #ff6b6b;
  font-weight: bold;
  font-size: 1.1rem;
  transition: all 0.3s ease;
}

.ai-resource-center-step-nav-item-in-progress {
  background-color: #feca57;
}

.ai-resource-center-step-nav-item-completed {
  background-color: #28a745;
}

.ai-resource-center-step-nav-item:hover {
  transform: scale(1.1);
}

.ai-resource-center-step {
  margin: 40px 0;
  padding: 35px;
  border-radius: 16px;
  background-color: #ffeeee;
  transition: all 0.3s ease;
}

.ai-resource-center-step:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}

.ai-resource-center-step-in-progress {
  background-color: #fff5e6;
}

.ai-resource-center-step-completed {
  background-color: #e8f5e9;
}

.ai-resource-center-step-title {
  font-size: 2.35rem;
  color: #333;
  margin-bottom: 22px;
  text-align: center;
  font-weight: 600;
}

.ai-resource-center-step-description {
  font-size: 1.35rem;
  color: #666;
  margin-bottom: 35px;
  text-align: center;
  line-height: 1.6;
}

.ai-resource-center-tool-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(325px, 1fr));
  gap: 28px;
}

.ai-resource-center-tool-card {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 28px;
  text-decoration: none;
  color: inherit;
  transition: all 0.3s ease-in-out;
  border: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.ai-resource-center-tool-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.ai-resource-center-resource-status {
  font-weight: bold;
  margin-bottom: 15px;
  font-size: 1.05rem;
}

.ai-resource-center-tool-icon {
  margin-bottom: 20px;
}

.ai-resource-center-tool-icon img {
  width: 70px;
  height: 70px;
  object-fit: contain;
}

.ai-resource-center-tool-title {
  font-size: 1.5rem;
  margin-bottom: 18px;
  color: #0000FF;
  font-weight: 600;
}

.ai-resource-center-tool-text {
  font-size: 1.05rem;
  line-height: 1.5;
  color: #333;
}

.ai-resource-center-video-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.ai-resource-center-video {
  width: 100%;
  height: auto;
}

@media (max-width: 1200px) {
  .ai-resource-center-steps-container {
    width: 95%;
  }

  .ai-resource-center-tool-grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

@media (max-width: 768px) {
  .ai-resource-center-steps-container {
    width: 100%;
    padding: 20px;
  }

  .ai-resource-center-main-title {
    font-size: 2.25rem;
  }

  .ai-resource-center-step-title {
    font-size: 1.85rem;
  }
  
  .ai-resource-center-step-description {
    font-size: 1.15rem;
  }

  .ai-resource-center-tool-grid {
    grid-template-columns: 1fr;
  }
  
  .ai-resource-center-step {
    padding: 25px;
  }
}

/* Popup Overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Slightly darker overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000000000;
}

/* Popup Content */
.popup-content {
  position: relative;
  background-color: #ffffff;
  padding: 40px 30px;
  border-radius: 10px;
  max-width: 400px;
  width: 90%;
  box-sizing: border-box;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

/* Close Button */
.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  color: #999;
  cursor: pointer;
}

.close-button:hover {
  color: #555;
}

/* Form Container */
.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-title {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

.form-description {
  font-size: 16px;
  margin-bottom: 20px;
  color: #666;
  text-align: center;
}

.form-container input {
  padding: 12px 15px;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.form-container input:focus {
  border-color: #005d41;
  outline: none;
}

.submit-button {
  width: 100%;
  padding: 12px 15px;
  background-color: #005d41; /* Primary button color */
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #004a34; /* Darker shade on hover */
}

/* Success and Error Messages */
.success-message {
  color: #005d41;
  font-size: 18px;
  text-align: center;
}

.error-message {
  color: #dc3545;
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
}

/* Responsive Design */
@media (max-width: 480px) {
  .popup-content {
    padding: 30px 20px;
  }

  .form-title {
    font-size: 22px;
  }

  .form-description {
    font-size: 15px;
  }
}

.popup-content {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* MoASSP Resources Section Styles */
/* Side by Side Container */
.sideBySide {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap on smaller screens */
  justify-content: space-between;
  align-items: flex-start; /* Align items to the top */
  margin: 40px 0; /* Add some vertical spacing */
}

/* Side by Side Sections */
.sideBySide-section {
  flex: 1 1 45%; /* Flex items take up 45% of the container */
  box-sizing: border-box;
  margin: 0 20px; /* Horizontal margin between sections */
}

/* Adjust sections on smaller screens */
@media (max-width: 1024px) {
  .sideBySide-section {
    flex: 1 1 100%; /* Sections take full width */
    margin: 20px 0; /* Vertical margin between sections */
  }

  .sideBySide {
    justify-content: center; /* Center the sections */
  }
}

/* Section Container */
.MoASSP-course-section {
  padding: 60px 20px;
  background-color: #f9f9f9;
  box-sizing: border-box;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  margin: 16px 0;

}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

/* MoASSP Course Subtitle */
.MoASSP-course-sub-title {
  font-size: 32px !important;         /* Increased font size for prominence */
  font-weight: 700;          /* Bolder font weight for emphasis */
  color: #333;               /* Dark gray color for readability */
  text-align: center;
  margin-bottom: 40px;
  position: relative;
  font-family: 'Montserrat', sans-serif;  /* Modern, clean font */
  line-height: 1.2;          /* Adjusted line height for better spacing */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

/* Decorative underline */
.MoASSP-course-sub-title::after {
  content: '';
  width: 80px;               /* Increased width for balance with larger text */
  height: 4px;
  background-color: #005d41; /* Accent color consistent with branding */
  display: block;
  margin: 20px auto 0;
  border-radius: 2px;
}

/* Tool Grid */
.MoASSP-tool-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center; /* Center the grid items */
}

/* Tool Card */
.MoASSP-tool-card {
  text-decoration: none;
  color: inherit;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: calc(25% - 40px); /* Four cards per row */
  box-sizing: border-box;
}

.MoASSP-tool-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 16px 30px rgba(0, 0, 0, 0.1);
}

.MoASSP-tool-card:hover .MoASSP-tool-icon img {
  transform: scale(1.05);
}

.MoASSP-tool-card:hover .MoASSP-tool-title {
  color: #005d41;
}

/* Tool Icon */
.MoASSP-tool-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.MoASSP-tool-icon img {
  max-width: 100%;
  height: auto;
  width: 60px;
  height: 60px;
  transition: transform 0.3s ease;
}

/* Tool Title */
.MoASSP-tool-title {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 1.75rem;
  font-weight: 600;
  color: #333;
  text-align: center;
  margin-bottom: 10px;
}

/* Tool Text */
.MoASSP-tool-text {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #666;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .MoASSP-tool-card {
    width: calc(50% - 20px); /* Two cards per row on medium screens */
  }
}

@media (max-width: 600px) {
  .MoASSP-tool-card {
    width: 100%; /* One card per row on small screens */
  }

  .MoASSP-tool-grid {
    gap: 20px;
  }

  .MoASSP-tool-card {
    padding: 15px;
  }

  .MoASSP-tool-icon img {
    width: 50px;
    height: 50px;
  }

  .MoASSP-tool-title {
    font-size: 1.5rem;
  }

  .MoASSP-tool-text {
    font-size: 1.25rem;
  }
}

/* MoASSP Single Course Subtitle */
.MoASSP-single-course-sub-title {
  font-size: 32px !important;
  font-weight: 700;
  color: #333;
  text-align: center;
  margin-bottom: 40px;
  position: relative;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.2;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

/* Decorative underline */
.MoASSP-single-course-sub-title::after {
  content: '';
  width: 80px;
  height: 4px;
  background-color: #005d41;
  display: block;
  margin: 20px auto 0;
  border-radius: 2px;
}

/* Single Tool Grid */
.MoASSP-single-tool-grid {
  display: flex;
  justify-content: center; /* Center the card horizontally */
}

/* Single Tool Card */
.MoASSP-single-tool-card {
  text-decoration: none;
  color: inherit;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 80%; /* Make the card wider */
  max-width: 800px; /* Limit the maximum width */
  box-sizing: border-box;
  margin: 0 auto; /* Center the card within its container */
}

.MoASSP-single-tool-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 16px 30px rgba(0, 0, 0, 0.1);
}

.MoASSP-single-tool-card:hover .MoASSP-single-tool-icon img {
  transform: scale(1.05);
}

.MoASSP-single-tool-card:hover .MoASSP-single-tool-title {
  color: #005d41;
}

/* Single Tool Icon */
.MoASSP-single-tool-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.MoASSP-single-tool-icon img {
  max-width: 100%;
  height: auto;
  width: 80px;
  height: 80px;
  transition: transform 0.3s ease;
}

/* Single Tool Title */
.MoASSP-single-tool-title {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 2rem; /* Increased font size for emphasis */
  font-weight: 600;
  color: #333;
  text-align: center;
  margin-bottom: 15px;
}

/* Single Tool Text */
.MoASSP-single-tool-text {
  font-size: 1.25rem;
  line-height: 1.6;
  color: #666;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .MoASSP-single-tool-card {
    width: 90%;
    padding: 25px;
  }

  .MoASSP-single-tool-title {
    font-size: 1.75rem;
  }
}

@media (max-width: 768px) {
  .MoASSP-single-tool-card {
    width: 95%;
    padding: 20px;
  }

  .MoASSP-single-tool-icon img {
    width: 70px;
    height: 70px;
  }

  .MoASSP-single-tool-title {
    font-size: 1.5rem;
  }

  .MoASSP-single-tool-text {
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .MoASSP-single-tool-card {
    width: 100%;
    padding: 15px;
  }

  .MoASSP-single-tool-icon img {
    width: 60px;
    height: 60px;
  }

  .MoASSP-single-tool-title {
    font-size: 1.25rem;
  }

  .MoASSP-single-tool-text {
    font-size: 1rem;
  }
}

/* MoASSP Navigation Bar Styles */

/* Navigation Bar Container */
.MoASSP-course-navbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the nav items */
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  z-index: 1000000;
  margin-bottom: 40px;
}

/* Navigation Item */
.MoASSP-course-nav-item {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-decoration: none;
  color: #333;
  font-size: 16px;
  padding: 15px 25px;
  border-radius: 8px;
  background-color: #ffffff;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  margin: 10px;
  cursor: pointer;
  text-align: center;
}

.MoASSP-course-nav-item:hover {
  background-color: #005d41;
  color: #ffffff;
  transform: translateY(-5px);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
}

.MoASSP-course-nav-item div {
  display: flex;
  align-items: center;
}

.MoASSP-course-nav-item span {
  margin-right: 8px;
  font-size: 1.2rem;
}

/* Cursor Pointer */
.MoASSP-cursor-pointer {
  cursor: pointer;
}

.MoASSP-course-nav-item.active {
  background-color: #004a34;
  color: #ffffff;
}

.MoASSP-course-nav-item:focus {
  outline: 2px solid #005d41;
  outline-offset: 4px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .MoASSP-course-nav-item {
    flex: 1 1 100%;
    margin: 5px 0;
  }
}

/* AASA Navbar */
.ASSA-navbar {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 0;
  z-index: 10000000;
}

.ASSA-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.ASSA-brand {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}

.ASSA-brand-right {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}

.ASSA-logo {
  height: 50px;
}

.ASSA-bars-btn {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.ASSA-nav-center {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  justify-content: center;
}

.ASSA-nav-item {
  margin: 0 15px;
  position: relative;
}

.ASSA-nav-link {
  text-decoration: none;
  color: #333;
  font-size: 18px; /* Increased font size from 16px to 18px */
  text-align: center;
  font-weight: 500;
  padding-bottom: 5px;
}

.ASSA-nav-link:hover {
  color: #005d41;
  border-bottom: 2px solid #005d41;
}

.ASSA-active-nav-item .ASSA-nav-link,
.ASSA-dropbtn:focus {
  color: #005d41;
  border-bottom: 2px solid #005d41;
  font-weight: 600;
}

/* Dropdown Styles */
.ASSA-dropdown {
  position: relative;
  display: inline-block;
}

.ASSA-dropbtn {
  background: none;
  border: none;
  color: #333;
  font-size: 18px; /* Increased font size */
  font-weight: 500;
  cursor: pointer;
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.ASSA-dropbtn:hover {
  color: #005d41;
}

/* Adjusted dropdown content for smoother transition */
.ASSA-dropdown-content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  background-color: #ffffff;
  min-width: 200px;
  box-shadow: 0px 8px 16px rgba(0,0,0,0.2);
  z-index: 1;
  flex-direction: column;
  padding: 10px 0;
  border-radius: 4px;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.ASSA-dropdown:hover .ASSA-dropdown-content {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.ASSA-dropdown-link {
  color: #333;
  font-size: 12px;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.ASSA-dropdown-link:hover {
  background-color: #f1f1f1;
  color: #005d41;
}

.no-scroll {
  overflow: hidden;
}

/* Mobile Navigation Popup */
.ASSA-nav-popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000001;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ASSA-mobile-nav-header {
  position: absolute;
  top: 20px;
  right: 20px;
}

.ASSA-close-btn {
  background: none;
  border: none;
  font-size: 36px;
  color: #ffffff;
  cursor: pointer;
}

.ASSA-mobile-nav-links {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ASSA-mobile-nav-link {
  color: #ffffff;
  font-size: 1.6rem; /* Increased font size */
  text-decoration: none;
  margin: 20px 0;
  transition: color 0.4s ease;
}

.ASSA-mobile-nav-link:hover {
  color: #00a651;
}

/* Responsive Styles */
@media (max-width: 992px) {
  .ASSA-nav-center {
    display: none;
  }

  .ASSA-bars-btn {
    display: block;
    filter: invert(1);
    z-index: 1000000000000;
  }

  .ASSA-container {
    justify-content: space-between;
  }

  .ASSA-brand-right {
    display: none;
  }
}

/* MoASSP Header Styles */

.MoASSP-header {
  padding-top: 100px;
  background-color: #f9f9f9;
  text-align: center;
}

.MoASSP-header-content {
  max-width: 800px;
  margin: 0 auto;
}

.MoASSP-header-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .MoASSP-header-title {
    font-size: 2rem;
  }
}

/* MoASSP Navigation Styles */

/* Navbar */
.MoASSP-navbar {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 0;
  z-index: 10000000;
  position: fixed;
}

.MoASSP-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.MoASSP-container-about{
flex-direction: column;
}

.MoASSP-brand {
  display: flex;
  align-items: center;
}

.MoASSP-brand-right {
  display: flex;
  align-items: center;
}

.MoASSP-logo {
  height: 50px;
}

.MoASSP-bars-btn {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.MoASSP-nav-center {
  display: flex;
  align-items: center;
}

.MoASSP-nav-item {
  margin: 0 15px;
}

.MoASSP-nav-link {
  text-decoration: none;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  transition: color 0.3s;
}

.MoASSP-nav-link:hover {
  color: #005d41;
}

.MoASSP-active-nav-item .MoASSP-nav-link {
  color: #005d41;
  font-weight: 700;
}

/* Mobile Navigation Popup */
.MoASSP-nav-popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000001;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.MoASSP-mobile-nav-header {
  position: absolute;
  top: 20px;
  right: 20px;
}

.MoASSP-close-btn {
  background: none;
  border: none;
  font-size: 36px;
  color: #ffffff;
  cursor: pointer;
}

.MoASSP-mobile-nav-links {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MoASSP-mobile-nav-link {
  color: #ffffff;
  font-size: 1.5rem;
  text-decoration: none;
  margin: 20px 0;
}

.MoASSP-mobile-nav-link:hover {
  color: #00a651;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .MoASSP-navbar-brand{
    max-width: 81px;
  }

  .MoASSP-bars-btn {
    display: block;
    filter: invert(1);
    z-index: 1000000000000;
  }

  .MoASSP-nav-center {
    display: none;
  }

  /* .MoASSP-brand-right {
    display: none;
  } */

  .MoASSP-container {
    justify-content: space-between;
  }
}

.no-scroll {
  overflow: hidden;
}

/* Header Styles */
.MoASSP-header {
  background-image: linear-gradient(135deg, #005d41 0%, #005d41 120%);
  color: #ffffff;
  padding: 100px 0 60px 0;
  text-align: center;
}

.AASA-header {
  background-image: linear-gradient(60deg, #ff007f 0%, #ee6632 100%);
}

.breakoutColor{
  color: #5231a3 !important;
}

.breakoutColor::after{
  background-color: #5231a3 !important;
}

.breakoutBackground{
  background-color: #5231a3 !important;
}

.breakoutBackground:hover{
  background-color: #242424 !important;
}

.MoASSP-header-title {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 20px;
}

.MoASSP-header-subtitle {
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 0;
}

/* Section Styles */
/* Section Container */
.MoASSP-section {
  padding: 60px 20px;
  background-color: #ffffff;
}

/* Container */
.MoASSP-container {
  max-width: 1200px;
  margin: 0 auto;
}

/* Section Title */
.MoASSP-section-title {
  font-size: 32px;
  font-weight: 700;
  color: #005d41;
  text-align: center;
  margin-bottom: 40px;
  position: relative;
}

.MoASSP-section-title::after {
  content: '';
  width: 80px;
  height: 4px;
  background-color: #00a651;
  display: block;
  margin: 20px auto 0;
  border-radius: 2px;
}

/* Testimonial */
.MoASSP-testimonial {
  font-style: italic;
  color: #333;
  margin: 40px auto;
  max-width: 800px;
  text-align: center;
  position: relative;
  padding: 20px;
  background-color: #f9f9f9;
  border-left: 4px solid #00a651;
  font-size: 16px;
}

.MoASSP-testimonial cite {
  display: block;
  margin-top: 20px;
  font-size: 12px;
  color: #555;
  font-style: normal;
}

/* Statistics Section */
.MoASSP-stats {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 60px;
}

.MoASSP-stat {
  flex: 1 1 30%;
  margin: 10px;
  text-align: center;
  padding: 30px 20px;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.MoASSP-stat-number {
  font-size: 48px;
  color: #005d41;
  margin-bottom: 10px;
}

.MoASSP-stat-text {
  font-size: 16px;
  color: #555;
}

/* Modules Section */
.MoASSP-modules {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 60px;
}

.MoASSP-module {
  width: calc(50% - 20px); /* Each module takes up 50% minus margin */
  margin-bottom: 40px;
  padding: 20px; /* Add padding inside the module */
  background-color: #fff; /* Set a white background for contrast */
  box-sizing: border-box;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  border-radius: 8px; /* Optional: Add rounded corners */
}

/* Add margin to the right for spacing between columns */
.MoASSP-module:nth-child(odd) {
  margin-right: 20px;
}

/* Center the last module if it's alone in a row */
.MoASSP-module:nth-child(odd):last-child {
  margin-right: auto;
  margin-left: auto;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .MoASSP-module {
    width: 100%; /* Modules take full width on smaller screens */
    margin-right: 0; /* Reset right margin */
  }
}

/* Original Styles */
.MoASSP-module-title {
  font-size: 24px;
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.MoASSP-module-text {
  font-size: 16px;
  color: #555;
  max-width: 800px;
  margin: 0 auto 20px auto;
  text-align: center;
}

.MoASSP-module-testimonial {
  font-style: italic;
  color: #333;
  margin: 20px auto;
  max-width: 800px;
  text-align: center;
  position: relative;
  padding: 20px;
  background-color: #f9f9f9;
  border-left: 4px solid #00a651;
  font-size: 16px;
}

.MoASSP-module-testimonial cite {
  display: block;
  margin-top: 10px;
  font-size: 12px;
  color: #555;
  font-style: normal;
}

/* Resources List */
.MoASSP-resources-list {
  list-style-type: none;
  padding: 0;
  max-width: 800px;
  margin: 0 auto 60px auto;
  flex-direction: column;
}

.MoASSP-resources-list li {
  font-size: 16px;
  color: #555;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  position: relative;
  padding-left: 25px;
}

.MoASSP-resources-list li::before {
  content: '•';
  color: #00a651;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
}

/* Contact Section */
.MoASSP-contact-section {
  text-align: center;
  padding: 40px 20px;
  max-width: 800px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

/* Section Title */
.MoASSP-contact-section .MoASSP-section-title {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 700;
  color: #005d41;
  font-family: 'Montserrat', sans-serif;
}

/* Section Text */
.MoASSP-contact-section .MoASSP-text {
  margin-bottom: 30px;
  font-size: 16px;
  color: #555;
}

/* Contact Form */
.MoASSP-contact-form {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}
.MoASSP-input {
  padding: 12px 15px;
  width: 100%;
  max-width: 350px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 12px;
  transition: border-color 0.3s ease;
  flex: 1 1 auto;
}

.MoASSP-input:focus {
  border-color: #00a651;
  outline: none;
}

.MoASSP-submit-button {
  padding: 12px 30px;
  background-color: #005d41;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  flex: 0 0 auto;
}

.MoASSP-submit-button:hover {
  background-color: #004a34;
  transform: translateY(-2px);
}

.MoASSP-submit-button:active {
  transform: translateY(0);
}

.MoASSP-success-message {
  font-size: 1.5rem;
  color: #005d41;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Error Message */
.MoASSP-error-message {
  color: #ff4d4f;
  font-size: 1rem;
  margin-top: 10px;
}

.MoASSP-input,
.MoASSP-submit-button {
  transition: all 0.3s ease;
}

/* Responsive Design */
@media (max-width: 600px) {
  .MoASSP-contact-form {
    flex-direction: column;
    align-items: center;
  }

  .MoASSP-input,
  .MoASSP-submit-button {
    max-width: 100%;
    width: 100%;
  }

  .MoASSP-submit-button {
    margin-top: 15px;
  }
}

.MoASSP-text{
  font-size: 24px;
  text-align: center;
}
/* Responsive Design */
@media (max-width: 768px) {
  .MoASSP-text{
    font-size: 18px;
  }

  .MoASSP-stats {
    flex-direction: column;
    align-items: center;
  }

  .MoASSP-stat {
    flex: 1 1 100%;
    margin: 10px 0;
  }
}


@media (max-width: 480px) {
  .MoASSP-text{
    font-size: 16px ;
  }
  .MoASSP-header {
    padding: 80px 0 40px 0;
  }

  .MoASSP-header-title {
    font-size: 2rem;
  }

  .MoASSP-header-subtitle {
    font-size: 1rem;
  }

  .MoASSP-section-title {
    font-size: 24px;
  }

  .MoASSP-module-title {
    font-size: 20px;
  }

  .MoASSP-stats {
    margin-bottom: 40px;
  }

  .MoASSP-module {
    margin-bottom: 40px;
  }

  .MoASSP-module-testimonial {
    font-size: 16px;
  }
}

/* Scroll to Top Button */
.MoASSP-scroll-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #005d41; /* Your primary accent color */
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s, visibility 0.3s;
  opacity: 0.8;
  z-index: 1000;
}

.MoASSP-scroll-to-top:hover {
  opacity: 1;
}

@media (max-width: 768px) {
  .MoASSP-scroll-to-top {
    width: 40px;
    height: 40px;
    font-size: 20px;
    bottom: 20px;
    right: 20px;
  }
}

.MoASSP-apply-button-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #005d41; /* Your primary color */
  color: #ffffff;
  padding: 8px 16px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  transition: background-color 0.3s ease;
  animation: greenpulse 2s ease-in-out infinite; 
}

.MoASSP-apply-button-nav:hover {
  background-color: #004a34;
}

@media (max-width: 768px) {
  .MoASSP-apply-button-nav {
    padding: 6px 12px;
    font-size: 12px;
  }
  #MoASSP-second-logo {
    display: none !important;
  }
  #MoASSP-apply-button {
    display: block !important;
  }
}

.yayFontAwesome{
  filter: invert(.75);
}

/* Footer Styles */
.MoASSP-footer {
  background-color: #005d41; /* Primary color */
  color: #ffffff;
  padding: 40px 20px;
  text-align: left;
  text-align: center;
}

.MoASSP-footer-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 20px;
}

.MoASSP-footer-section {
  flex: 1 1 30%;
  margin-bottom: 20px;
}

.MoASSP-footer-heading {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #ffffff;
}

.MoASSP-footer-text {
  font-size: 12px;
  line-height: 1.6;
  color: #f1f1f1;
}

.MoASSP-footer-link {
  display: block;
  font-size: 12px;
  color: #f1f1f1;
  text-decoration: none;
  margin-bottom: 10px;
  transition: color 0.3s ease;
}

.MoASSP-footer-link:hover {
  color: #00a651; /* Secondary color */
}

.MoASSP-footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 20px;
  text-align: center;
}

.MoASSP-footer-copyright {
  font-size: 12px;
  color: #f1f1f1;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .MoASSP-footer-container {
    flex-direction: column;
    align-items: center;
  }

  .MoASSP-footer-section {
    flex: 1 1 100%;
    text-align: center;
    margin-bottom: 30px;
  }

  .MoASSP-footer-link {
    margin-bottom: 8px;
  }
}


/* Activities Section */
.MoASSP-course-section {
  padding: 60px 20px;
}

.MoASSP-course-sub-title {
  font-size: 2rem;
  font-weight: 700;
  color: #005d41;
  text-align: center;
  margin-bottom: 40px;
}

/* Activities Wrapper */
.MoASSP-activities-wrapper {
  max-width: 1800px;
  margin: 0 auto;
}

/* Activity Navigation Bar */
.MoASSP-activity-nav-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.MoASSP-activity-nav-item {
  margin: 0 10px;
  padding: 10px 20px;
  background-color: #005d41;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.MoASSP-activity-nav-item:hover {
  background-color: #004a34;
}

/* Activity Separator */
.MoASSP-activity-separator {
  border: none;
  height: 2px;
  background-color: #e0e0e0;
  margin: 60px 0 40px 0;
}

/* Activity Content */
.MoASSP-course-content {
  margin-bottom: 60px;
}

.MoASSP-activity-title {
  font-size: 28px;
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.MoASSP-activity-number {
  font-size: 32px;
  font-weight: 700;
  color: #005d41;
  margin-right: 10px;
}

/* Links Section */
.MoASSP-links-section {
  margin-bottom: 30px;
}

.MoASSP-links-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.MoASSP-links-title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
}

.MoASSP-links-container {
  display: flex;
  flex-wrap: wrap;
}

.MoASSP-link-icon {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  text-decoration: none;
  color: #333;
  font-size: 1.25rem;
  font-weight: 600;
  padding: 10px 15px;
  background-color: #e8f5e9;
  border-left: 5px solid #005d41;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.MoASSP-link-icon:hover {
  background-color: #d0e8dc;
}

.MoASSP-link-icon-img {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.MoASSP-link-label {
  font-size: 1.25rem;
  font-weight: 600;
}

/* Activity Steps */
.MoASSP-activity-steps {
  display: flex;
  flex-direction: column;
}

.MoASSP-activity-step {
  margin-bottom: 30px;
}

.MoASSP-activity-direction {
  font-size: 20px; /* Larger font size */
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

/* CopyToClipboard Component */
.textToCopy {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 5px;
  font-family: monospace;
  color: #555;
  position: relative;
  cursor: pointer;
}

.copy-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.2rem;
}

.copy-notification {
  position: absolute;
  top: -30px;
  right: 10px;
  background-color: #005d41;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .MoASSP-activity-nav-bar {
    flex-direction: column;
    align-items: center;
  }

  .MoASSP-activity-nav-item {
    margin: 5px 0;
  }

  .MoASSP-activity-title,
  .MoASSP-activity-number {
    font-size: 1.75rem;
  }

  .MoASSP-links-title {
    font-size: 1.25rem;
  }

  .MoASSP-activity-direction {
    font-size: 1.3rem;
  }

  .textToCopy {
    font-size: 1.1rem;
  }

  .MoASSP-link-icon-img {
    width: 35px;
    height: 35px;
  }
}

.textWhite{
  color: white !important;
}

.MoASSP-footer-success{
  background-color: #242424;
}

.MoASSP-contact-form{
  margin-bottom: 0 !important;
}

.greenBtn{
  background-color: #0000FF;
}

.noMarginTop {
  margin-top: 0px !important;
}

.noPaddingTop {
  padding-top: 0px !important;
}

.aasaHomeHeader {
  background-image: url("../src/images/AI_webheader_1000x300.jpg");
  background-size: 100% auto; /* Make the image cover the full width */
  background-repeat: no-repeat; /* Prevent repeating */
  background-position: top center; /* Align image to the top center */
  width: 100vw; /* Full width of the viewport */
  height: calc(100vw * 0.3);
  margin-top: 50px;
}
.summitTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3% 0;
  background-color: #fff;
}
.summitTitleWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}
.summitTitle img {
  width: 150px;
  height: auto;
}
.summitTitle h4 {
  font-size: 20px;
  line-height: 25px;
  color: #000;
}
.ASSA-nav-link:hover {
  color: #ee6632 !important;
  border-bottom: 2px solid #ee6632 !important;
}
.ASSA-active-nav-item .ASSA-nav-link,
.ASSA-dropbtn:focus {
  color: #ee6632 !important;
  border-bottom: 2px solid #ee6632 !important;
}
.ASSA-dropbtn:hover {
  color: #ee6632 !important;
}
.ASSA-dropdown-link:hover {
  color: #ee6632 !important;
}
#aiSuperSUmmitPartners {
  padding: 5% 10%;
  background-color: #f9f9f9;
}

#aiSuperSUmmitPartners .heading {
  text-align: center;
  color: black;
  font-size: 16px;
}

.aisupersummitcards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Create flexible columns */
  column-gap: 1.25rem; /* Space between columns */
  row-gap: 1.25rem; /* Space between rows */
  justify-content: center; /* Center grid content */
  align-items: center; /* Align items vertically */
  margin-top: 3rem;
  max-width: calc(6 * 200px + 5 * 1.25rem); /* Limit grid width for 6 images per row */
  margin-left: auto;
  margin-right: auto;
}

.aisupersummitcards img {
  max-width: 200px; /* Limit maximum image size */
  width: 100%; /* Make images responsive */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Prevent distortion */
  padding: 8px; /* Add spacing around images */
}

.AASA-footer {
  /* need to add gradient color here  */
  background-image: linear-gradient(60deg, #ff007f 0%, #ee6632 100%);
  background-color: none !important;
}
.AASA-scroll-to-top {
  background-color: #ee6632 !important;
}
.aiSupperSummitBackgroundColor {
  background-color: #ee6632 !important;
}
.aiSupperSummitColor {
  color: #ee6632;
}
.aiSupperSummitColor::after {
  background-color: #ee6632;
}
.AASA-course-nav-item:hover {
  background-color: #ee6632 !important;
}

.AASA-tool-card:hover .AASA-tool-title {
  color: #ee6632 !important;
}

.AASA-single-tool-title:hover {
  color: #ee6632 !important;
}

.AASA-single-course-sub-title::after {
  background-color: #ee6632;
}
.MoASSP-single-tool-card:hover .MoASSP-single-tool-title {
  color: #ee6632 !important;
}

.AASA-course-sub-title{
 color: #242424;
}

.AASA-course-sub-title::after{
  color: #242424;
 }

 #ASSA-main-logo,
  #ASSA-second-logo {
    display: none !important;
  }
  .aasaHomeHeader {
    margin-top: 30px;
  }
  .ASSA-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
/* Responsive CSS */
@media (max-width: 768px) {
  .aasaHomeHeader {
    height: calc(100vw * 0.3); /* Maintain aspect ratio based on image dimensions */
  }
  .summitTitle {
    flex-direction: column;
    padding: 5% 0;
  }
  .summitTitleWrapper {
    flex-direction: column;
    gap: 10px;
    max-width: 90%;
  }
  .summitTitle img {
    width: 100px;
  }
  .summitTitle h4 {
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
  }
  #aiSuperSUmmitPartners {
    padding: 8% 5%;
  }
  .aisupersummitcards {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); /* Smaller columns for mobile */
    max-width: 100%; /* Remove max-width constraint for smaller screens */
  }

  .aisupersummitcards img {
    max-width: 150px; /* Adjust maximum image size for smaller screens */
  }
  .MoASSP-course-nav-item:hover {
    background-color: #ee6632 !important;
  }
}

/* Larger tablets and smaller desktops */
@media (min-width: 769px) and (max-width: 1024px) {
  .aasaHomeHeader {
    height: calc(100vw * 0.3); /* Maintain aspect ratio */
  }
  
  .summitTitleWrapper {
    gap: 15px;
    max-width: 95%;
  }
  .summitTitle img {
    width: 120px;
  }
  .summitTitle h4 {
    font-size: 18px;
    line-height: 22px;
  }
  .aisupersummitcards {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.25rem;
  }
  .AASA-course-nav-item:hover {
    background-color: #ee6632 !important;
  }
}

@media (max-width: 768px) {
  #ASSA-main-logo,
  #ASSA-second-logo {
    display: none !important;
  }
  .aasaHomeHeader {
    margin-top: 30px;
  }
  .ASSA-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
