
/* ==================
   1. GENERAL STYLES
   ================== */
  
   body {
    /* font-family: "Space Grotesk", sans-serif; */
    margin: 0 ;
    padding: 0 ;
    color: #000 ;
    background-color: #fff;
    line-height: 1.6 ;
  }

  .ff-font{
    font-family: "Space Grotesk", sans-serif !important;
  }

.ff-home {
  width: 100%;
}

.ff-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* ==================
     2. HEADER & NAVIGATION
     ================== */
.ff-header {
  background-color: #fff;
  padding: 20px 0 0 0;
  border-bottom: 1px solid #eee;
}

.ff-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.ff-logo-container {
  flex: 0 0 auto;
  max-width: 180px;
}

.ff-logo {
  width: 100%;
  height: auto;
  max-height: 45px;
  object-fit: contain;
}

.ff-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
}

.ff-nav-link {
  text-decoration: none;
  color: #000;
  font-weight: 500;
  font-size: 1.4rem; /* Increased from 1.1rem */
  letter-spacing: -0.01em;
  transition: color 0.3s ease;
  position: relative;
  padding-bottom: 2px;
}

.ff-nav-link:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #005d41; /* Green accent */
  transition: width 0.2s ease;
}

.ff-nav-link:hover:after {
  width: 100%;
}

.ff-nav-link:hover {
  color: #005d41; /* Green accent */
}

.ff-cta {
  background-color: #005d41; /* Green accent */
  color: #fff;
  padding: 10px 22px;
  border-radius: 4px;
  transition: all 0.3s ease;
  border: 1px solid #005d41; /* Green accent */
}

.ff-cta:hover {
  background-color: #fff;
  color: #005d41; /* Green accent */
}

.ff-cta:hover:after {
  display: none;
}

/* Dropdown Navigation */
.ff-dropdown {
  position: relative;
  display: inline-block;
}

.ff-dropdown-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-family: inherit;
  font-size: 1.4rem; /* Increased from 1.1rem */
  font-weight: 400;

  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0;
}

.ff-dropdown-arrow {
  font-size: 0.6rem;
  margin-left: 4px;
  transition: transform 0.3s ease;
  color: #005d41; /* Green accent */
}

.ff-dropdown::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 15px;
  background-color: transparent;
}

.ff-dropdown-content {
  display: none;
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  min-width: 200px;
  box-shadow: 0 2px 10px rgba(0, 93, 65, 0.15); /* Green accent shadow */
  border-radius: 4px;
  z-index: 1000;
  overflow: hidden;
  border-top: 3px solid #005d41; /* Green accent border */
}

.ff-dropdown:hover .ff-dropdown-content,
.ff-dropdown-content:hover {
  display: block;
}

.ff-dropdown-item {
  display: block;
  text-decoration: none;
  color: #000;
  padding: 14px 18px;
  font-weight: normal;
  font-size: 1.2rem; /* Increased from 1.05rem */
  transition: background-color 0.2s ease;
  text-align: center;
  border-bottom: 1px solid #f5f5f5;
}

.ff-dropdown-item:last-child {
  border-bottom: none;
}

.ff-dropdown-item:hover {
  background-color: rgba(0, 93, 65, 0.05); /* Green accent background */
  color: #005d41; /* Green accent text */
}

/* Fixed Header on Scroll */
.ff-header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* ==================
     3. HERO SECTION
     ================== */
.ff-hero {
  text-align: center;
  padding: 90px 20px 110px;
  background-color: #fff;
  position: relative;
}

.ff-hero:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(
    90deg,
    transparent,
    #005d41,
    transparent
  ); /* Green accent line */
  opacity: 0.3;
}

.ff-hero-title {
  font-size: 4rem; /* Increased from 3.2rem */
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 32px;
  letter-spacing: -0.02em;
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  color: #242424;
}

.ff-hero-title span {
  color: #005d41; /* Green accent for part of the title */
}

.ff-hero-subtitle {
  font-size: 1.8rem; /* Increased from 1.4rem */
  margin-bottom: 45px;
  color: #555;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.5;
}

.ff-hero-buttons {
  display: flex;
  justify-content: center;
  gap: 18px;
}

/* ==================
     4. BUTTONS
     ================== */
.ff-btn {
  display: inline-block;
  padding: 18px 36px; /* Increased from 14px 28px */
  border-radius: 5px;
  font-size: 1.3rem; /* Increased from 1.1rem */
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease;
}

.ff-btn-primary {
  background-color: #005d41; /* Green accent */
  color: #fff;
  border: 1px solid #005d41; /* Green accent */
}

.ff-btn-primary:hover {
  background-color: #004732; /* Darker green on hover */
  border-color: #004732;
}

.ff-btn-secondary {
  background-color: #fff;
  color: #005d41; /* Green accent */
  border: 1px solid #005d41; /* Green accent */
}

.ff-btn-secondary:hover {
  background-color: rgba(0, 93, 65, 0.05); /* Light green background */
}

/* ==================
     5. QUICK ACCESS NAVIGATION
     ================== */
.ff-quick-access {
  background-color: #242424;
  padding: 20px 0 !important;
  padding: 0;
  margin-top: -1px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.ff-quick-access-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.ff-quick-access-item {
  position: relative;
  padding: 40px 30px;
  text-decoration: none;
  color: #fff;
  text-align: center;
  transition: all 0.3s ease;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  /* background-color: rgba(0, 93, 65, 0.2); */
}

.ff-quick-access-item:last-child {
  border-right: none;
}

.ff-quick-access-item:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.ff-quick-access-title{
    color: #fff;
    transition: transform 0.3s ease;
}

.ff-quick-access-item:hover .ff-quick-access-title {
  transform: translateY(-3px);
}

.ff-quick-access-item:hover .ff-quick-access-description {
  opacity: 0.9;
}

.ff-quick-access-item:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #005d41;
  transform: translateX(-50%);
  transition: width 0.3s ease;
}

.ff-quick-access-item:hover:after {
  width: 50px;
}


.ff-quick-access-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;
  z-index: 2;
}

.ff-quick-access-title {
  font-size: 1.7rem;
  font-weight: 600;
  letter-spacing: -0.01em;
  margin-bottom: 14px;
  transition: transform 0.3s ease;
}

.ff-quick-access-description {
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.7);
  transition: opacity 0.3s ease;
  max-width: 260px;
  margin: 0 auto;
  line-height: 1.4;
}

/* Add subtle icons to enhance visual appeal */
.ff-quick-access-item:before {
  content: "";
  position: absolute;
  top: 25px;
  right: 25px;
  width: 15px;
  height: 15px;
  border-top: 2px solid rgba(255, 255, 255, 0.2);
  border-right: 2px solid rgba(255, 255, 255, 0.2);
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.ff-quick-access-item:hover:before {
  opacity: 1;
  transform: rotate(45deg) translate(3px, -3px);
}

/* ==================
     6. SECTION STYLES
     ================== */
.ff-section {
  padding: 90px 0; /* Increased from 80px */
}

.ff-section-title {
  font-size: 3rem; /* Increased from 2.3rem */
  font-weight: bold;
  margin-bottom: 28px;
  text-align: left;
  letter-spacing: -0.01em;
  color: #242424;
  text-align: center;
}

.ff-section-title span {
  color: #005d41; /* Green accent for part of title */
}

.ff-section-description {
  font-size: 1.6rem; /* Increased from 1.25rem */
  margin-bottom: 40px;
  text-align: left;
  color: #555;
}

/* ==================
     7. PROBLEM SECTION
     ================== */
.ff-problem {
  background-color: #f9f9f9;
  position: relative;
  padding-top: 40px;
}

.ff-problem:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 5px;
  background-color: #005d41; /* Green accent vertical line */
  opacity: 0.2;
}

.ff-split {
  display: flex;
  flex-wrap: wrap;
  gap: 50px; /* Increased from 40px */
  align-items: center;
}

.ff-text {
  flex: 1;
  min-width: 300px;
}

.ff-graphic {
  flex: 1;
  min-width: 300px;
  text-align: center;
}

.ff-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 93, 65, 0.15); /* Green accent shadow */
}

/* ==================
     8. SOLUTION SECTION
     ================== */
.ff-solution {
  background-color: #fff;
}

.ff-solution .ff-section-title {
  text-align: center;
  margin-bottom: 50px;
}

.ff-columns {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.ff-column {
  flex: 1;
  min-width: 280px; /* Increased from 250px */
  text-align: center;
  padding: 40px 30px; /* Increased padding */
  border-radius: 5px;
  background-color: #f9f9f9;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-bottom: 3px solid transparent;
}

.ff-column:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
  border-bottom: 3px solid #005d41; /* Green accent border on hover */
}

.ff-column-title {
  font-size: 2.1rem; /* Increased from 1.7rem */
  font-weight: bold;
  margin-bottom: 20px;

  color: #005d41; /* Green accent */
}

.ff-column-description {
  font-size: 1.4rem; /* Increased from 1.15rem */
  color: #555;
}

/* ==================
     9. RESOURCES SECTION
     ================== */
.ff-resources {
  background-color: #fff !important;
  position: relative;
}

/* .ff-resources:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 150px;
  height: 150px;
  background-color: rgba(0, 93, 65, 0.05);
  border-radius: 50%;
  transform: translate(40%, 40%);
  z-index: 0;
} */

.ff-resources-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 25px; /* Increased from 20px */
  margin-top: 50px;
  position: relative;
  z-index: 1;
}

.ff-resource-card {
  flex: 1;
  min-width: 280px; /* Increased from 250px */
  background-color: #fff;
  padding: 35px; /* Increased from 30px */
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  color: #000;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.ff-resource-card:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 0;
  background-color: #005d41; /* Green accent */
  transition: height 0.3s ease;
}

.ff-resource-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.ff-resource-card:hover:after {
  height: 100%;
}

.ff-resource-title {
  font-size: 1.9rem; /* Increased from 1.5rem */
  font-weight: bold;
  margin-bottom: 16px;
}

.ff-resource-description {
  font-size: 1.4rem; /* Increased from 1.15rem */
  color: #555;
}

/* ==================
     10. TESTIMONIALS SECTION
     ================== */
.ff-testimonials {
  background-color: #f9f9f9;
}

.ff-testimonials .ff-section-title {
  text-align: center;
  margin-bottom: 50px;
}

.ff-testimonial-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 35px; /* Increased from 30px */
  justify-content: center;
}

.ff-testimonial-card {
  flex: 1;
  min-width: 320px; /* Increased from 300px */
  padding: 35px; /* Increased from 30px */
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.ff-testimonial-card:before {
  content: '"';
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 5rem;
  color: rgba(0, 93, 65, 0.08); /* Green accent quote */
  font-family: Georgia, serif;
  line-height: 1;
}

.ff-testimonial-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.ff-testimonial-text {
  font-size: 1.5rem; /* Increased from 1.25rem */
  margin-bottom: 28px;
  color: #333;
  line-height: 1.6;
  position: relative;
  z-index: 1;
}

.ff-testimonial-author {
  font-size: 1.35rem; /* Increased from 1.15rem */
  font-weight: bold;
  color: #005d41; /* Green accent */
  text-align: right;
}

.ff-testimonial-more {
  text-align: center;
  margin-top: 40px;
}

/* ==================
     11. CTA SECTION
     ================== */
.ff-cta-section {
  text-align: center;
  background-color: #242424;
  color: #fff;
  padding: 90px 0;
  position: relative;
  overflow: hidden;
}

.ff-cta-section:before {
  content: "";
  position: absolute;
  top: -50px;
  right: -50px;
  width: 200px;
  height: 200px;
  background-color: #005d41; /* Green accent */
  opacity: 0.2;
  border-radius: 50%;
}

.ff-cta-section .ff-section-title {
  text-align: center;
  color: #fff;
  margin-bottom: 25px;
  position: relative;
  z-index: 1;
}

.ff-cta-section .ff-section-description {
  text-align: center;
  color: #ddd;
  max-width: 700px; /* Increased from 600px */
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 35px;
  position: relative;
  z-index: 1;
  font-size: 1.6rem; /* Increased from 1.25rem */
}

/* CTA Separator */
.ff-cta-separator {
  font-size: 1.5rem;
  color: #fff;
  margin: 0 15px;
  align-self: center;
  font-style: italic;
}

/* Adjust existing CTA buttons styles to accommodate the separator */
.ff-cta-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  flex-wrap: wrap;
  gap: 15px;
}


.ff-cta-section .ff-btn-primary {
  background-color: #fff;
  color: #000;
  border: 1px solid #fff;
  padding: 18px 35px; /* Increased padding */
  font-size: 1.3rem; /* Increased from 1.2rem */
}

.ff-cta-section .ff-btn-primary:hover {
  background-color: #005d41; /* Green accent */
  color: #fff;
  border: 1px solid #005d41; /* Green accent */
}

.ff-cta-section .ff-btn-secondary {
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 35px; /* Increased padding */
  font-size: 1.3rem; /* Increased from 1.2rem */
}

.ff-cta-section .ff-btn-secondary:hover {
  background-color: #005d41; /* Green accent */
  color: #fff;
  border-color: #005d41; /* Green accent */
}

.ff-cta-section .ff-btn-primary,
.ff-cta-section .ff-btn-secondary {
  padding: 20px 38px; /* Increased from 18px 35px */
  font-size: 1.4rem; /* Increased from 1.3rem */
}


/* ==================
     12. COMMUNITY SECTION
     ================== */
.ff-community {
  background-color: #f9f9f9;
  text-align: center;
  padding: 80px 0;
}

.ff-community .ff-btn-primary {
  background-color: #005d41; /* Green accent */
  border-color: #005d41; /* Green accent */
}

.ff-community .ff-btn-primary:hover {
  background-color: #004732; /* Darker green on hover */
  border-color: #004732;
}

/* ==================
     13. RESPONSIVE DESIGN
     ================== */
@media (max-width: 768px) {
  /* Header */
  .ff-header-content {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .ff-logo-container {
    margin-bottom: 10px;
  }

  .ff-nav {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
  }

  /* Dropdown */
  .ff-dropdown-content {
    position: static;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #f9f9f9;
    display: none;
    transform: none;
    left: 0;
  }

  .ff-dropdown:hover .ff-dropdown-content,
  .ff-dropdown-content:hover {
    display: block;
  }

  .ff-dropdown-item {
    padding: 12px;
    text-align: center;
  }

  /* Hero */
  .ff-hero {
    padding: 60px 20px 80px;
  }

  .ff-hero-title {
    font-size: 2.8rem; /* Increased from 2.5rem */
  }

  /* Quick Access */
  .ff-quick-access-grid {
    grid-template-columns: 1fr;
  }

  .ff-quick-access-item {
    border-right: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 30px 20px;
  }

  .ff-quick-access-item:last-child {
    border-bottom: none;
  }

  .ff-quick-access-title {
    font-size: 1.4rem;
  }

  .ff-quick-access-description {
    font-size: 1rem;
  }

  /* Sections Layout */
  .ff-section {
    padding: 60px 0; /* Increased from 50px */
  }

  .ff-section-title {
    font-size: 2.2rem;
  }

  .ff-split {
    flex-direction: column;
  }

  .ff-columns {
    flex-direction: column;
  }

  .ff-resources-grid {
    flex-direction: column;
  }

  .ff-testimonial-cards {
    flex-direction: column;
  }

  .ff-cta-buttons {
    flex-direction: column;
    gap: 15px;
  }

  /* Mobile sticky CTA */
  .ff-mobile-cta {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #005d41; /* Green accent */
    padding: 15px;
    text-align: center;
    z-index: 1000;
  }

  .ff-mobile-cta .ff-btn {
    width: 100%;
    max-width: 300px;
  }
}
/* ==================
   FOOTER STYLES
   ================== */

.ff-footer {
  color: #fff;
}

/* Contact Form Section */
.ff-footer-contact {
  background-color: #f9f9f9;
  padding: 80px 0;
  color: #242424;
}

.ff-section-header {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 50px;
}

.ff-section-header h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 15px;
  color: #242424;
}

.ff-section-header p {
  font-size: 1.3rem;
  line-height: 1.5;
  color: #555;
}

.ff-contact-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 50px;
  max-width: 1200px;
  margin: 0 auto;
}

.ff-contact-info {
  padding: 30px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  height: fit-content;
}

.ff-contact-method {
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;
}

.ff-contact-icon {
  background-color: #005d41;
  color: white;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  flex-shrink: 0;
}

.ff-contact-details h4 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 5px;
  color: #242424;
}

.ff-contact-details a {
  color: #555;
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 1.1rem;
}

.ff-contact-details a:hover {
  color: #005d41;
}

.ff-contact-support {
  border-top: 1px solid #eee;
  padding-top: 20px;
  margin-top: 10px;
}

.ff-contact-support p {
  color: #666;
  font-size: 1.05rem;
  line-height: 1.6;
}

/* Contact Form */
.ff-contact-form-wrapper {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.ff-contact-form {
  padding: 40px;
}

.ff-contact-form h3 {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 25px;
  color: #242424;
  text-align: center;
}

.ff-form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
}

.ff-form-group {
  margin-bottom: 25px;
}

.ff-form-group label {
  display: block;
  font-weight: 600;
  margin-bottom: 8px;
  color: #333;
  font-size: 1.05rem;
}

.ff-form-group input,
.ff-form-group textarea {
  width: 100%;
  padding: 15px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1rem;
  font-family: inherit;
  transition: all 0.3s ease;
  background-color: #f9f9f9;
}

.ff-form-group input:focus,
.ff-form-group textarea:focus {
  outline: none;
  border-color: #005d41;
  background-color: #fff;
  box-shadow: 0 0 0 3px rgba(0, 93, 65, 0.1);
}

.ff-form-group input::placeholder,
.ff-form-group textarea::placeholder {
  color: #aaa;
}

.ff-form-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-top: 10px;
}

/* Submit button - already defined */
.ff-btn-submit {
  width: 100%;
}

/* Meeting button */
.ff-btn-meeting {
  width: 100%;
  padding: 15px;
  font-size: 1.1rem;
  font-weight: 600;
  background-color: #2f2f2f;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ff-btn-meeting:hover {
  background-color: #1a1a1a;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

.ff-btn-submit:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 93, 65, 0.15);
}

.ff-btn-submit:disabled {
  background-color: #aaa;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.ff-form-error {
  color: #e74c3c;
  margin-bottom: 15px;
  font-size: 0.95rem;
  background-color: rgba(231, 76, 60, 0.1);
  padding: 10px;
  border-radius: 6px;
  border-left: 3px solid #e74c3c;
}

.ff-form-success {
  padding: 60px 40px;
  text-align: center;
  color: #242424;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ff-success-icon {
  color: #4CAF50;
  margin-bottom: 25px;
}

.ff-form-success h3 {
  font-size: 2rem;
  margin-bottom: 15px;
  color: #242424;
}

.ff-form-success p {
  font-size: 1.2rem;
  color: #555;
  max-width: 80%;
  margin: 0 auto;
}

/* Main Footer */
.ff-footer-main {
  background-color: #242424;
  padding: 70px 0 30px;
}

.ff-footer-content {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}

.ff-footer-brand {
  flex: 1.5;
  min-width: 250px;
}

.ff-footer-logo {
  max-width: 160px;
  height: auto;
  margin-bottom: 20px;
  filter: invert(1);
}

.ff-footer-tagline {
  color: #ccc;
  font-size: 1.1rem;
  margin-bottom: 25px;
  line-height: 1.6;
}

/* Social Media Icons in Footer */
.ff-social-icons {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.ff-social-icons a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  color: white;
  transition: all 0.3s ease;
}

.ff-social-icons a:hover {
  background-color: #005d41;
  color: white;
  transform: translateY(-4px);
}

.ff-footer-links {
  flex: 2;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: space-between;
}

.ff-footer-section {
  flex: 1;
  min-width: 150px;
}

.ff-footer-title {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 25px;
  color: white;
  position: relative;
}

.ff-footer-title:after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 40px;
  height: 3px;
  background-color: #005d41;
}

.ff-footer-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.ff-footer-menu li {
  margin-bottom: 15px;
}

.ff-footer-menu a {
  text-decoration: none;
  color: #aaa;
  font-size: 1.05rem;
  transition: all 0.3s ease;
  display: inline-block;
}

.ff-footer-menu a:hover {
  color: white;
  transform: translateX(5px);
}

.ff-footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  border-top: 1px solid #333;
  margin-top: 50px;
}

.ff-copyright {
  color: #888;
  font-size: 0.95rem;
}

.ff-footer-legal {
  display: flex;
  gap: 20px;
}

.ff-footer-legal a {
  color: #888;
  text-decoration: none;
  font-size: 0.95rem;
  transition: color 0.3s ease;
}

.ff-footer-legal a:hover {
  color: white;
}

/* Responsive adjustments */
@media (max-width: 1100px) {
  .ff-contact-container {
    grid-template-columns: 1fr;
    gap: 30px;
  }
  
  .ff-contact-info {
    padding: 25px;
  }
}

@media (max-width: 992px) {
  .ff-footer-content {
    flex-direction: column;
    gap: 40px;
  }
  
  .ff-footer-links {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .ff-form-buttons {
    grid-template-columns: 1fr;
  }
  .ff-section-header h2 {
    font-size: 2rem;
  }
  
  .ff-section-header p {
    font-size: 1.1rem;
  }
  
  .ff-form-row {
    grid-template-columns: 1fr;
    gap: 0;
  }
  
  .ff-contact-form {
    padding: 30px 20px;
  }
  
  .ff-footer-bottom {
    flex-direction: column;
    gap: 20px;
    text-align: center;
  }
}

@media (max-width: 576px) {
  .ff-footer-contact {
    padding: 60px 0;
  }
  
  .ff-contact-method {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .ff-contact-icon {
    margin-bottom: 10px;
  }
  
  .ff-social-icons {
    justify-content: center;
  }
}

  /* ==================
   ABOUT PAGE STYLES
   ================== */

/* Page Header */
.ff-page-header {
  background-color: #242424;
  color: #fff;
  padding: 80px 0;
  text-align: center;
  position: relative;
}

.ff-page-header:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(90deg, transparent, #005d41, transparent);
  opacity: 0.3;
}

.ff-page-title {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  letter-spacing: -0.02em;
  color: #fff;
}

.ff-page-subtitle {
  font-size: 1.8rem;
  max-width: 700px;
  margin: 0 auto;
  color: rgba(255, 255, 255, 0.8);
}

/* About Founder Section */
.ff-about-founder {
  background-color: #f9f9f9;
  padding: 90px 0;
}

.ff-founder-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 60px;
}

.ff-founder-image {
  flex: 1;
  min-width: 300px;
  text-align: center;
}

.ff-profile-image {
  max-width: 100%;
  height: auto;
  border-radius: 6px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  border: 3px solid #fff;
}

.ff-founder-bio {
  flex: 2;
  min-width: 300px;
}

.ff-bio-text {
  font-size: 1.4rem;
  line-height: 1.6;
  color: #333;
  margin-bottom: 24px;
}

.ff-founder-cta { 
  margin-top: 30px;
}

/* Mission Text Styling */
.ff-mission-text {
  font-size: 2rem;
  line-height: 1.6;
  color: #333;
  margin-bottom: 0;
}

/* Mission Section */
.ff-about-mission {
  padding: 90px 0;
  background-color: #fff;
}

.ff-mission-content {
  max-width: 900px;
  margin: 0 auto;
}

.ff-mission-content .ff-section-title {
  text-align: center;
  margin-bottom: 40px;
}

.ff-mission-content b {
  color: #005d41;
  font-weight: 700;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .ff-mission-text {
    font-size: 1.3rem;
  }
  
  .ff-about-mission {
    padding: 60px 0;
  }
}

/* Approach Section */
.ff-about-approach {
  background-color: #fff;
  padding: 90px 0;
}

.ff-approach-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 40px;
  margin-top: 50px;
}

.ff-approach-item {
  background-color: #f9f9f9;
  padding: 40px;
  border-radius: 6px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.ff-approach-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.ff-approach-item:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 0;
  background-color: #005d41;
  transition: height 0.3s ease;
}

.ff-approach-item:hover:before {
  height: 100%;
}

.ff-approach-title {
  font-size: 1.8rem;
  margin-bottom: 20px;
  font-weight: 600;
  color: #000;
}

.ff-approach-description {
  font-size: 1.3rem;
  color: #555;
  line-height: 1.5;
}

/* Value Proposition */
.ff-about-value {
  background-color: #f9f9f9;
  color: #242424;
  padding: 90px 0;
  position: relative;
  overflow: hidden;
}

.ff-about-value:before {
  content: '';
  position: absolute;
  top: -50px;
  right: -50px;
  width: 200px;
  height: 200px;
  background-color: #005d41;
  opacity: 0.1;
  border-radius: 50%;
}

.ff-value-content {
  position: relative;
  z-index: 2;
  max-width: 900px;
  margin: 0 auto;
}

.ff-about-value .ff-section-title {
  color: #242424;
  text-align: center;
  margin-bottom: 25px;
}

.ff-about-value .ff-section-description {
  color: #242424;
  text-align: center;
  margin-bottom: 40px;
  font-size: 1.5rem;
}

.ff-value-list {
  list-style: none;
  padding: 0;
  margin: 0 0 40px 0;
}

.ff-value-list li {
  font-size: 1.4rem;
  margin-bottom: 20px;
  padding-left: 30px;
  position: relative;
  color: #242424;
}

.ff-value-list li:before {
  content: '✓';
  position: absolute;
  left: 0;
  color: #005d41;
  font-weight: bold;
}

.ff-value-highlight {
  color: #242424;
  font-weight: 600;
}

.ff-value-cta {
  text-align: center;
  margin-top: 40px;
}

/* Resources Section */
.ff-about-resources {
  background-color: #fff;
  padding: 90px 0;
}

.ff-about-resources .ff-section-title,
.ff-about-resources .ff-section-description {
  text-align: center;
}

.ff-about-resources .ff-resources-grid {
  margin-top: 50px;
}

.ff-resource-link {
  display: inline-block;
  margin-top: 15px;
  color: #005d41;
  font-weight: 600;
  font-size: 1.1rem;
  transition: transform 0.3s ease;
}

.ff-resource-card:hover .ff-resource-link {
  transform: translateX(5px);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .ff-page-title {
    font-size: 3rem;
  }
  
  .ff-page-subtitle {
    font-size: 1.5rem;
  }
  
  .ff-founder-grid {
    flex-direction: column;
    gap: 40px;
  }
  
  .ff-founder-image {
    order: 1;
  }
  
  .ff-founder-bio {
    order: 2;
  }
  
  .ff-bio-text {
    font-size: 1.3rem;
  }
  
  .ff-approach-item {
    padding: 30px;
  }
  
  .ff-value-list li {
    font-size: 1.3rem;
  }
}
/* ==================
   TESTIMONIALS PAGE STYLES
   ================== */

/* Page Header Styles */
.ff-testimonials-header {
  background-color: #242424;
  color: #fff;
  text-align: center;
}

.ff-testimonials-header .ff-page-subtitle {
  max-width: 700px;
  margin: 0 auto;
  font-size: 2rem; /* Increased from 1.8rem */
}

/* Toggle Navigation - Much More Obvious */
.ff-testimonials-toggle {
  display: flex;
  justify-content: center;
  margin: 0 auto 60px; /* Increased bottom margin */
  background-color: #f4f4f4;
  border-radius: 15px; /* Increased border radius */
  padding: 10px; /* Increased padding */
  max-width: 750px; /* Increased width */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1); /* More pronounced shadow */
  position: relative;
  border: 2px solid #eaeaea; /* Added border */
}

/* Added a label above the toggle - keeping this */
.ff-testimonials-toggle::before {
  content: "Select a category";
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.2rem;
  color: #555;
  font-weight: 600;
}

.ff-toggle-btn {
  flex: 1;
  padding: 20px 0; /* Increased padding */
  border: none;
  background: none;
  font-size: 1.4rem; /* Increased from 1.15rem */
  font-weight: 600;
  color: #555;
  cursor: pointer;
  border-radius: 10px; /* Increased border radius */
  transition: all 0.3s ease;
  position: relative;
  margin: 0 5px; /* Added margin between buttons */
}

/* Modified hover styles - only apply to non-active buttons */
.ff-toggle-btn:not(.ff-toggle-active):hover {
  color: #005d41;
  background-color: rgba(0, 93, 65, 0.05); /* Subtle hover background */
}

.ff-toggle-active {
  background-color: #005d41;
  color: #fff !important;
  box-shadow: 0 4px 15px rgba(0, 93, 65, 0.3); /* More pronounced shadow */
  transform: translateY(-2px); /* Slightly raised effect */
}

/* Hover state for active button - keep text white */
.ff-toggle-active:hover {
  background-color: #004d37; /* Slightly darker green on hover */
  color: #fff !important;
}


.ff-tab-description {
  text-align: center;
  max-width: 700px;
  margin: 0 auto 50px;
  font-size: 1.5rem; /* Increased from 1.25rem */
  color: #444; /* Darker for better readability */
  font-weight: 500; /* Added weight for emphasis */
}

/* Loading State */
.ff-loading {
  text-align: center;
  padding: 60px 0;
  font-size: 1.4rem; /* Increased from 1.25rem */
  color: #666;
}

.ff-empty-state {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 40px;
  text-align: center;
  margin: 30px auto;
  max-width: 600px;
  color: #666;
  font-size: 1.4rem; /* Increased from 1.25rem */
}

/* ==================
   CASE STUDIES STYLES
   ================== */

.ff-case-studies-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns on desktop */
  gap: 40px; /* Increased from 30px */
}

.ff-case-study-card {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.1); /* Increased shadow */
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ff-case-study-header {
  padding: 30px; /* Increased from 25px */
  background-color: #f9f9f9;
  border-bottom: 1px solid #eee;
}

.ff-case-study-title {
  font-size: 1.7rem; /* Increased from 1.4rem */
  font-weight: 600;
  margin-bottom: 10px; /* Increased from 8px */
  color: #242424;
}

.ff-case-study-date {
  font-size: 1.15rem; /* Increased from 1rem */
  color: #666;
}

.ff-case-study-embed-container {
  padding: 15px;
  height: 350px; /* Fixed height */
  position: relative;
}

.ff-case-study-embed {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
}

.ff-case-study-embed iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

/* ==================
   TESTIMONIALS STYLES
   ================== */

.ff-testimonials-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr)); /* Increased from 320px */
  gap: 35px; /* Increased from 30px */
  margin-bottom: 40px;
}

.ff-testimonial-card {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.08);
  padding: 35px; /* Increased from 30px */
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 250px;
}

.ff-testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.12);
}

.ff-testimonial-featured {
  background-color: #f9f9f9;
  border-top: 4px solid #005d41;
}

.ff-testimonial-card:before {
  content: "\"";
  position: absolute;
  top: 20px;
  left: 25px;
  font-size: 7rem; /* Increased from 6rem */
  color: rgba(0, 93, 65, 0.08);
  font-family: Georgia, serif;
  line-height: 0;
  z-index: 1;
}

.ff-testimonial-text {
  font-size: 1.45rem; /* Increased from 1.2rem */
  line-height: 1.6;
  color: #333;
  margin-bottom: 25px;
  position: relative;
  z-index: 2;
  flex-grow: 1;
}

.ff-testimonial-author-info {
  border-top: 1px solid #eee;
  padding-top: 20px;
  margin-top: auto;
}

.ff-testimonial-author {
  font-size: 1.3rem; /* Increased from 1.1rem */
  font-weight: 600;
  color: #242424;
  margin-bottom: 5px;
}

.ff-testimonial-position,
.ff-testimonial-school {
  font-size: 1.15rem; /* Increased from 1rem */
  color: #555; /* Slightly darker for better readability */
}

/* Responsive Adjustments */
@media (max-width: 992px) {
  .ff-case-studies-grid {
    grid-template-columns: 1fr; /* One column on tablets/mobile */
  }
  
  .ff-testimonials-grid {
    grid-template-columns: 1fr;
  }
  
  .ff-case-study-embed-container {
    height: 300px;
  }
  
  .ff-testimonials-toggle::before {
    top: -25px;
  }
}

@media (max-width: 768px) {
  .ff-testimonials-toggle {
    flex-direction: column;
    gap: 10px; /* Increased from 8px */
    padding: 15px; /* Increased from 10px */
    background: none;
    box-shadow: none;
    border: none;
  }
  
  .ff-testimonials-toggle::before {
    top: -25px;
  }
  
  .ff-toggle-btn {
    border: 1px solid #eee;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08); /* Increased shadow */
    padding: 15px; /* Increased from 12px */
    margin-bottom: 8px;
    margin-left: 0;
    margin-right: 0;
  }
  
  .ff-toggle-active {
    box-shadow: 0 4px 12px rgba(0, 93, 65, 0.35); /* Increased shadow */
  }
  
  .ff-toggle-active::after {
    display: none; /* Remove the indicator dot on mobile */
  }
  
  .ff-case-study-embed-container {
    height: 250px;
  }
  
  .ff-testimonial-card {
    padding: 30px 25px; /* Increased from 25px 20px */
  }
  
  .ff-testimonial-text {
    font-size: 1.35rem; /* Slightly reduced for mobile but still larger than original */
  }
}

@media (max-width: 576px) {
  .ff-page-title {
    font-size: 2.7rem; /* Increased from 2.5rem */
  }
  
  .ff-page-subtitle {
    font-size: 1.35rem; /* Increased from 1.2rem */
  }
  
  .ff-tab-description {
    font-size: 1.25rem; /* Increased from 1.1rem */
  }
  
  .ff-case-study-title {
    font-size: 1.5rem; /* Increased from 1.3rem */
  }
  
  .ff-case-study-embed-container {
    height: 220px;
  }
  
  .ff-testimonial-text {
    font-size: 1.3rem; /* Slightly reduced but still larger than original */
  }
}

/* Testimonial Carousel Component Styles */
/* Testimonial Carousel Component Styles */
.ff-testimonial-carousel {
  width: 100%;
  position: relative;
  margin: 30px 0;
}

.ff-carousel-container {
  position: relative;
  display: flex;
  align-items: center;
  margin: 40px 0 20px;
}

.ff-testimonial-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  width: 100%;
  transition: all 0.4s ease;
}

.ff-testimonial-card {
  background-color: #fff;
  border-radius: 12px;
  padding: 35px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  height: 100%;
}

.ff-testimonial-card:before {
  content: "\"";
  position: absolute;
  top: 15px;
  left: 20px;
  font-size: 7rem;
  color: rgba(0, 93, 65, 0.08);
  font-family: Georgia, serif;
  line-height: 0;
}

.ff-testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.12);
}

.ff-testimonial-featured {
  background-color: #f9f9f9;
  border-top: 4px solid #005d41;
}

.ff-testimonial-text {
  font-size: 1.45rem;
  line-height: 1.6;
  color: #333;
  margin-bottom: 25px;
  position: relative;
  z-index: 1;
}

.ff-testimonial-author {
  font-size: 1.2rem;
  font-weight: 600;
  color: #005d41;
  text-align: right;
}

/* Carousel Navigation Arrows */
.ff-carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: #005d41;
  border: none;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  transition: all 0.3s ease;
}

.ff-carousel-arrow:hover {
  background-color: #008f64;
  color: #fff;
  box-shadow: 0 5px 15px rgba(0, 93, 65, 0.2);
}

.ff-carousel-prev {
  left: -23px;
}

.ff-carousel-next {
  right: -23px;
}

/* Pagination Dots */
.ff-carousel-pagination {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  gap: 8px;
}

.ff-carousel-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #e0e0e0;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 0;
}

.ff-carousel-dot:hover {
  background-color: #bdbdbd;
}

.ff-carousel-dot-active {
  background-color: #005d41;
  transform: scale(1.2);
}

.ff-testimonial-more {
  text-align: center;
  margin-top: 30px;
}

.ff-testimonial-loading {
  text-align: center;
  padding: 40px;
  font-size: 1.2rem;
  color: #666;
  background-color: #fff;
  border-radius: 12px;
  margin: 40px 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .ff-testimonial-cards {
    grid-template-columns: 1fr;
    gap: 25px;
  }
  
  .ff-carousel-arrow {
    width: 40px;
    height: 40px;
  }
  
  .ff-carousel-prev {
    left: -20px;
  }
  
  .ff-carousel-next {
    right: -20px;
  }
}

@media (max-width: 768px) {
  .ff-testimonial-card {
    padding: 30px 25px;
  }
  
  .ff-testimonial-text {
    font-size: 1.3rem;
  }
  
  .ff-testimonial-author {
    font-size: 1.1rem;
  }
  
  .ff-carousel-arrow {
    display: none; /* Hide arrows on mobile */
  }
}

@media (max-width: 480px) {
  .ff-testimonial-text {
    font-size: 1.2rem;
  }
}

/* ==================
   TRAINING PORTAL STYLES
   ================== */

   .ff-training-header {
    background-color: #242424;
    color: #fff;
    text-align: center;
  }
  
  .ff-training-portal {
    padding: 60px 0;
  }
  
  .ff-training-instructions {
    text-align: center;
    max-width: 800px;
    margin: 0 auto 50px;
  }
  
  /* Search Bar Styles */
  .ff-search-container {
    position: relative;
    max-width: 600px;
    margin: 30px auto;
  }
  
  .ff-search-input {
    width: 100%;
    padding: 18px 60px 18px 25px;
    font-size: 1.2rem;
    border: 2px solid #e0e0e0;
    border-radius: 12px;
    background-color: #ffffff;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  }
  
  .ff-search-input:focus {
    outline: none;
    border-color: #005d41;
    box-shadow: 0 5px 20px rgba(0, 93, 65, 0.1);
  }
  
  .ff-search-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    color: #555;
    pointer-events: none;
  }
  
  /* Schools Grid Styles */
  .ff-schools-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 30px;
    margin-top: 40px;
  }
  
  .ff-school-card {
    background-color: #fff;
    border-radius: 12px;
    padding: 30px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.06);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
  }
  
  .ff-school-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.1);
  }
  
  .ff-school-icon {
    width: 90px;
    height: 90px;
    margin: 0 auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .ff-school-icon img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  .ff-school-name {
    font-size: 1.5rem;
    font-weight: 600;
    color: #242424;
    margin-bottom: 8px;
  }
  
  .ff-school-type {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 20px;
  }
  
  .ff-school-access-btn {
    background-color: #f5f5f5;
    color: #005d41;
    padding: 10px 15px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 1.1rem;
    transition: all 0.3s ease;
  }
  
  .ff-school-card:hover .ff-school-access-btn {
    background-color: #005d41;
    color: #fff;
  }
  
  /* Loading State */
  .ff-loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 0;
    text-align: center;
  }
  
  .ff-loading-spinner {
    width: 50px;
    height: 50px;
    border: 4px solid rgba(0, 93, 65, 0.1);
    border-radius: 50%;
    border-top: 4px solid #005d41;
    animation: ff-spin 1s linear infinite;
    margin-bottom: 20px;
  }
  
  @keyframes ff-spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* No Results State */
  .ff-no-results {
    text-align: center;
    padding: 50px 20px;
    background-color: #f9f9f9;
    border-radius: 12px;
    margin: 30px auto;
    max-width: 600px;
    font-size: 1.2rem;
    color: #666;
  }
  
  /* School Not Listed Section */
  .ff-not-listed {
    background-color: #f9f9f9;
    text-align: center;
    padding: 70px 0;
  }
  
  .ff-not-listed .ff-section-title {
    margin-bottom: 20px;
  }
  
  .ff-not-listed .ff-section-description {
    max-width: 800px;
    margin: 0 auto 30px;
    font-size: 1.25rem;
  }
  
  .ff-cta-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-top: 30px;
  }
  
  /* Responsive Styles */
  @media (max-width: 992px) {
    .ff-schools-grid {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 25px;
    }
  }
  
  @media (max-width: 768px) {
    .ff-training-instructions {
      margin-bottom: 30px;
    }
    
    .ff-search-input {
      padding: 15px 55px 15px 20px;
      font-size: 1.1rem;
    }
    
    .ff-school-card {
      padding: 25px 20px;
    }
    
    .ff-school-name {
      font-size: 1.3rem;
    }
    
    .ff-school-icon {
      width: 75px;
      height: 75px;
      margin-bottom: 15px;
    }
  }
  
  @media (max-width: 576px) {
    .ff-schools-grid {
      grid-template-columns: 1fr;
      max-width: 350px;
      margin-left: auto;
      margin-right: auto;
    }
    
    .ff-not-listed .ff-section-description {
      font-size: 1.1rem;
    }
    
    .ff-cta-buttons {
      flex-direction: column;
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
    }
    
    .ff-cta-separator {
      margin: 5px 0;
    }
    
    .ff-btn {
      width: 100%;
      text-align: center;
    }
  }

  /* Training Info Page Styles */
.ff-training-header {
  background-color: #242424;
  padding: 80px 0;
  text-align: center;
}

/* Overview Section */
.ff-training-overview {
  padding: 90px 0;
  background-color: #fff;
}

.ff-overview-content {
  max-width: 1100px;
  margin: 0 auto;
}

.ff-overview-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 30px;
  margin-top: 50px;
}

.ff-overview-card {
  background-color: #f9f9f9;
  border-radius: 12px;
  padding: 35px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ff-overview-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.ff-overview-icon {
  width: 60px;
  height: 60px;
  background-color: rgba(0, 93, 65, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  color: #005d41;
}

.ff-overview-icon svg {
  width: 30px;
  height: 30px;
}

.ff-overview-card h3 {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 15px;
  color: #242424;
}

.ff-overview-card p {
  font-size: 1.3rem;
  color: #005d41;
  font-weight: 500;
  margin-bottom: 20px;
}

.ff-feature-list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 1.2rem;
  color: #555;
  display: flex;
  flex-direction: column;
}

.ff-feature-list li {
  padding-left: 25px;
  margin-bottom: 12px;
  position: relative;
}

.ff-feature-list li:before {
  content: "✓";
  position: absolute;
  left: 0;
  color: #005d41;
  font-weight: bold;
}

/* Modules Section */
.ff-training-modules {
  background-color: #f9f9f9;
  padding: 90px 0;
}

.ff-module-card {
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 40px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.ff-module-header {
  background-color: #005d41;
  padding: 25px 35px;
  color: #fff;
}

.ff-module-header h3 {
  font-size: 1.8rem;
  font-weight: 600;
  margin: 0;
  color: #fff;;
}

.ff-module-content {
  padding: 35px;
}

.ff-module-objective {
  font-size: 1.4rem;
  color: #333;
  margin-bottom: 25px;
}

.ff-module-details {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

.ff-module-takeaways {
  flex: 1;
  min-width: 280px;
}

.ff-module-takeaways h4 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #242424;
}

.ff-module-takeaways ul {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
}

.ff-module-takeaways li {
  font-size: 1.25rem;
  margin-bottom: 10px;
  color: #555;
}

.ff-module-quote {
  flex: 1;
  min-width: 280px;
}

.ff-module-quote blockquote {
  font-size: 1.25rem;
  font-style: italic;
  color: #555;
  border-left: 4px solid #005d41;
  padding-left: 20px;
  margin: 0;
}

.ff-module-quote cite {
  display: block;
  margin-top: 15px;
  font-style: normal;
  font-weight: 600;
  color: #333;
}

.ff-module-stat {
  flex: 1;
  min-width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ff-stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.ff-stat-value {
  font-size: 3.5rem;
  font-weight: 700;
  color: #005d41;
  margin-bottom: 10px;
}

.ff-stat-label {
  font-size: 1.3rem;
  color: #555;
  max-width: 280px;
}

/* What's Included Section */
.ff-whats-included {
  padding: 90px 0;
  background-color: #fff;
}

.ff-included-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 30px;
  margin-top: 50px;
}

.ff-included-card {
  background-color: #f9f9f9;
  border-radius: 12px;
  padding: 35px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  height: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.ff-included-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.ff-included-icon {
  width: 60px;
  height: 60px;
  background-color: rgba(0, 93, 65, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  color: #005d41;
}

.ff-included-icon svg {
  width: 30px;
  height: 30px;
}

.ff-included-card h3 {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: #242424;
}

.ff-included-list {
  list-style: none;
  padding: 0;
  margin: 0 0 25px 0;
  display: flex;
  flex-direction: column;
}

.ff-included-list li {
  padding-left: 25px;
  margin-bottom: 12px;
  position: relative;
  font-size: 1.2rem;
  color: #555;
}

.ff-included-list li:before {
  content: "✓";
  position: absolute;
  left: 0;
  color: #005d41;
  font-weight: bold;
}

.ff-included-quote {
  border-top: 1px solid #e0e0e0;
  padding-top: 20px;
  margin-top: auto;
}

.ff-included-quote blockquote {
  font-size: 1.2rem;
  font-style: italic;
  color: #555;
  margin: 0;
}

.ff-included-quote cite {
  display: block;
  margin-top: 10px;
  font-style: normal;
  font-weight: 600;
  color: #333;
}

/* Delivery Options */
.ff-delivery-options {
  background-color: #f9f9f9;
  padding: 90px 0;
}

.ff-delivery-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  margin-top: 50px;
}

.ff-delivery-card {
  background-color: #fff;
  border-radius: 12px;
  padding: 40px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  flex: 1;
  min-width: 280px;
  max-width: 350px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.ff-delivery-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.ff-delivery-icon {
  width: 70px;
  height: 70px;
  background-color: rgba(0, 93, 65, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 25px;
  color: #005d41;
}

.ff-delivery-icon svg {
  width: 35px;
  height: 35px;
}

.ff-delivery-card h3 {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 15px;
  color: #242424;
}

.ff-delivery-card p {
  font-size: 1.3rem;
  color: #555;
}

/* Testimonials Highlight */
.ff-training-testimonials {
  padding: 90px 0;
  background-color: #fff;
}

.ff-testimonial-highlights {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 40px;
  margin: 50px 0;
}

.ff-testimonial-highlight {
  background-color: #f9f9f9;
  border-radius: 12px;
  padding: 35px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  position: relative;
}

.ff-testimonial-highlight:before {
  content: '"';
  position: absolute;
  top: 20px;
  left: 25px;
  font-size: 6rem;
  color: rgba(0, 93, 65, 0.1);
  font-family: Georgia, serif;
  line-height: 0;
}

.ff-testimonial-highlight p {
  font-size: 1.4rem;
  line-height: 1.6;
  color: #333;
  margin-bottom: 25px;
  position: relative;
  z-index: 1;
}

.ff-testimonial-source {
  border-top: 1px solid #e0e0e0;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
}

.ff-testimonial-role {
  font-size: 1.2rem;
  font-weight: 600;
  color: #005d41;
}

.ff-testimonial-school {
  font-size: 1.1rem;
  color: #555;
}

.ff-testimonial-cta {
  text-align: center;
  margin-top: 40px;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .ff-overview-grid {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
  
  .ff-testimonial-highlights {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .ff-module-details {
    flex-direction: column;
    gap: 30px;
  }
  
  .ff-delivery-card {
    min-width: 100%;
  }
  
  .ff-training-header {
    padding: 60px 0;
  }
  
  .ff-section {
    padding: 70px 0;
  }
  
  .ff-section-title {
    font-size: 2.5rem;
  }
}

@media (max-width: 576px) {
  .ff-overview-card,
  .ff-included-card,
  .ff-delivery-card {
    padding: 25px;
  }
  
  .ff-module-header {
    padding: 20px 25px;
  }
  
  .ff-module-content {
    padding: 25px;
  }
  
  .ff-testimonial-highlight {
    padding: 30px 25px;
  }
  
  .ff-cta-buttons {
    flex-direction: column;
    gap: 15px;
  }
}
/* Enhanced Floating CTA Button */
.ff-floating-cta {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 99;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.4s ease, transform 0.4s ease;
  pointer-events: none;
}

.ff-floating-cta-visible {
  opacity: 1;
  transform: translateY(0);
  pointer-events: all;
}

.ff-floating-btn {
  padding: 16px 28px;
  font-size: 1.15rem;
  font-weight: 600;
  border-radius: 50px;
  box-shadow: 0 6px 20px rgba(0, 93, 65, 0.35);
  display: flex;
  align-items: center;
  white-space: nowrap;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  letter-spacing: 0.02em;
  color: #fff;
  background-color: #005d41;
}

.ff-floating-btn:hover {
  transform: translateY(-4px);
  box-shadow: 0 10px 25px rgba(0, 93, 65, 0.5);
  background-color: #006d4b;
}

/* Enhanced pulse animation for more visibility */
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 93, 65, 0.7);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(0, 93, 65, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 93, 65, 0);
  }
}

.ff-floating-btn {
  animation: pulse 2.5s infinite;
}

/* Make the floating button adaptive for different screens while keeping it prominent */
@media (max-width: 992px) {
  .ff-floating-btn {
    padding: 14px 26px;
    font-size: 1.1rem;
  }
  
  .ff-floating-cta {
    bottom: 25px;
    right: 25px;
  }
}

@media (max-width: 768px) {
  .ff-floating-btn {
    padding: 12px 24px;
    font-size: 1.05rem;
  }
  
  .ff-floating-cta {
    bottom: 22px;
    right: 22px;
  }
  
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(0, 93, 65, 0.7);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(0, 93, 65, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(0, 93, 65, 0);
    }
  }
}

@media (max-width: 576px) {
  .ff-floating-btn {
    padding: 10px 20px;
    font-size: 1rem;
    text-align: center;
    margin-left: auto;
  }
  
  .ff-floating-cta {
    bottom: 20px;
    right: 20px;
    max-width: 70%;
  }
}

/* For very small screens */
@media (max-width: 375px) {
  .ff-floating-btn {
    padding: 10px 16px;
    font-size: 0.9rem;
  }
}

/* Resources Page Styles */
.ff-resources-header {
  position: relative;
}

/* Tabs Navigation */
.ff-resources-tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 30px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 5px;
}

.ff-tab-button {
  background: none;
  border: none;
  padding: 12px 20px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 500;
  color: #555;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.ff-tab-button:hover {
  background-color: #f0f0f0;
  color: #333;
}

.ff-tab-button.active {
  background-color: #005d41;
  color: white;
}

.ff-tab-icon {
  display: inline-flex;
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.ff-tab-icon svg {
  width: 100%;
  height: 100%;
}

/* Tab Content */
.ff-tab-content-container {
  position: relative;
  min-height: 400px;
}

.ff-tab-content {
  display: none;
  animation: fadeIn 0.3s ease-in-out;
}

.ff-tab-content.active {
  display: block;
}

.ff-resources-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background-color: #f9f9f9;
  border-radius: 8px;
  text-align: center;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .ff-resources-tabs {
    flex-direction: column;
    gap: 5px;
  }
  
  .ff-tab-button {
    width: 100%;
    padding: 12px 15px;
    justify-content: flex-start;
  }
}

/* Resource Card Styles */
.ff-resource-card {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.07);
  padding: 25px;
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid #eaeaea;
  overflow: hidden;
  position: relative;
}

.ff-resource-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.12);
  border-color: rgba(0, 93, 65, 0.2);
}

.ff-resource-card::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 0;
  background-color: #005d41;
  transition: height 0.3s ease;
}

.ff-resource-card:hover::after {
  height: 100%;
}

.ff-resource-card-inner {
  display: flex;
  gap: 20px;
}

.ff-resource-icon {
  flex: 0 0 50px;
  height: 50px;
  background-color: rgba(0, 93, 65, 0.1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #005d41;
}

.ff-resource-icon svg {
  width: 25px;
  height: 25px;
}

.ff-resource-content {
  flex: 1;
}

.ff-resource-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0 0 10px 0;
  color: #242424;
}

.ff-resource-description {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 15px;
  line-height: 1.5;
}

.ff-resource-meta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 15px;
  font-size: 0.9rem;
}

.ff-resource-type {
  background-color: rgba(0, 93, 65, 0.1);
  color: #005d41;
  padding: 4px 10px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 0.85rem;
}

.ff-resource-date {
  color: #777;
  display: flex;
  align-items: center;
}

.ff-resource-clicks {
  color: #777;
  display: flex;
  align-items: center;
  gap: 5px;
}

.ff-resource-link {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: #005d41;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.2s ease;
  font-size: 1.05rem;
  margin-top: 5px;
}

.ff-resource-link:hover {
  color: #007a56;
  gap: 12px; /* Creates an effect where the arrow moves slightly right */
}

/* Resource Grid Layout */
.ff-resources-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 25px;
  margin-top: 30px;
}

/* For YouTube Video Cards */
.ff-video-card .ff-resource-icon {
  color: #ff0000; /* YouTube red color */
  background-color: rgba(255, 0, 0, 0.1);
}

.ff-video-thumbnail {
  width: 100%;
  height: 180px;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 15px;
  position: relative;
}

.ff-video-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.ff-video-card:hover .ff-video-thumbnail img {
  transform: scale(1.05);
}

.ff-video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff0000;
  transition: all 0.3s ease;
}

.ff-video-card:hover .ff-video-play {
  background-color: #ff0000;
  color: white;
  transform: translate(-50%, -50%) scale(1.1);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .ff-resources-grid {
    grid-template-columns: 1fr;
  }
  
  .ff-resource-card-inner {
    flex-direction: column;
    gap: 15px;
  }
  
  .ff-resource-icon {
    margin-bottom: 5px;
  }
  
  .ff-resource-meta {
    gap: 10px;
  }
}

/* Featured resource tag */
.ff-resource-featured {
  position: relative;
  border-color: rgba(0, 93, 65, 0.3);
  background-color: rgba(0, 93, 65, 0.03);
}

.ff-resource-featured::before {
  content: 'Featured';
  position: absolute;
  top: -10px;
  right: 20px;
  background-color: #005d41;
  color: white;
  padding: 4px 10px;
  font-size: 0.8rem;
  font-weight: 600;
  border-radius: 3px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.ff-resource-featured-tag {
  display: none; /* Hidden because we use the ::before pseudo-element instead */
}

/* Video card styles */
.ff-video-card .ff-video-thumbnail {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.ff-video-card .ff-video-thumbnail img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.ff-video-card:hover .ff-video-thumbnail img {
  transform: scale(1.05);
}

.ff-video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff0000;
  transition: all 0.3s ease;
}

.ff-video-card:hover .ff-video-play {
  background-color: #ff0000;
  color: white;
  transform: translate(-50%, -50%) scale(1.1);
}

.ff-video-play svg {
  width: 24px;
  height: 24px;
}

/* Video grid layout */
.ff-videos-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 25px;
  margin-top: 30px;
}

@media (max-width: 768px) {
  .ff-videos-grid {
    grid-template-columns: 1fr;
  }
  
  .ff-video-play {
    width: 50px;
    height: 50px;
  }
  
  .ff-video-play svg {
    width: 20px;
    height: 20px;
  }
}
/* Loading spinner */
.ff-loading-spinner {
  border: 4px solid rgba(0, 93, 65, 0.1);
  border-radius: 50%;
  border-top: 4px solid #005d41;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.ff-resources-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  border-radius: 8px;
  background-color: #f5f5f5;
  text-align: center;
  margin-top: 20px;
}

/* Newsletter styles */
.ff-newsletter-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-top: 30px;
}

.ff-newsletter-info {
  padding: 25px;
  background: #f9f9f9;
  border-radius: 8px;
  margin-bottom: 30px;
}

.ff-newsletter-features {
  padding-left: 20px;
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  position: relative;
}

.ff-newsletter-info h3 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.ff-newsletter-info p {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.ff-newsletter-features li {
  font-size: 1.3rem;
  padding-left: 2rem;
  margin-bottom: 0.8rem;
  line-height: 1.4;
}

.ff-newsletter-features li::before {
  content: "→";
  position: absolute;
  left: 15px;
  color: #005d41;
}

.ff-newsletter-cta {
  padding: 30px;
  background: linear-gradient(135deg, #005d41 0%, #008564 100%);
  border-radius: 8px;
  color: white;
  text-align: center;
}

.ff-newsletter-cta h3 {
  color: white;
  margin-top: 0;
  font-size: 1.8rem;
  margin-bottom: 1re
}

.ff-newsletter-cta .ff-btn {
  margin-top: 20px;
  background-color: white;
  color: #005d41;
}

.ff-newsletter-cta .ff-btn:hover {
  background-color: #f0f0f0;
}

.ff-newsletter-cta p {
  font-size: 1.3rem;
  margin-bottom: 1.5rem;
}

.ff-newsletter-preview {
  padding: 30px;
  background: #f5f5f5;
  border-radius: 8px;
}

.ff-newsletter-samples {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.ff-newsletter-sample {
  display: block;
  padding: 15px;
  background: white;
  border-radius: 6px;
  text-decoration: none;
  color: #333;
  transition: all 0.2s ease;
  border: 1px solid #eaeaea;
}

.ff-newsletter-sample:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-color: #005d41;
}

.ff-newsletter-preview h3 {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
}

.ff-newsletter-sample-date {
  font-size: 1.1rem;
  color: #005d41;
  font-weight: 600;
  margin-bottom: 5px;
}

.ff-newsletter-sample-title {
  font-size: 1.3rem;
  line-height: 1.4;
  font-weight: 500;
}

@media (max-width: 768px) {
  .ff-newsletter-container {
    grid-template-columns: 1fr;
  }
}

/* Prompt Library Styles */
/* Centered Prompt Controls */
.ff-prompt-controls-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 0;
}

.ff-prompt-dropdown-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.ff-prompt-dropdown-label {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: #2d3748;
  font-weight: 600;
}

.ff-dropdown-button-large {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1.25rem 2rem;
  background-color: #ffffff;
  border: 2px solid #005d41;
  border-radius: 0.75rem;
  color: #242424;
  cursor: pointer;
  font-size: 1.4rem;
  text-align: left;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
}

.ff-dropdown-button-large:hover {
  background-color: #f0f9f6;
  transform: translateY(-2px);
}

.ff-dropdown-button-large.active {
  border-color: #005d41;
  background-color: #f0f9f6;
  box-shadow: 0 6px 12px rgba(0, 93, 65, 0.15);
}

.ff-dropdown-button-large svg {
  width: 24px;
  height: 24px;
  transition: transform 0.3s;
}

.ff-dropdown-button-large.active svg {
  transform: rotate(180deg);
}

.ff-dropdown-menu-large {
  position: absolute;
  z-index: 20;
  width: 100%;
  max-width: 600px;
  margin-top: 0.75rem;
  padding: 1rem 0;
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 0.75rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  max-height: 400px;
  overflow-y: auto;
}

.ff-dropdown-item {
  display: block;
  width: 100%;
  padding: 1rem 2rem;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  color: #4a5568;
  font-size: 1.2rem;
  transition: all 0.2s ease;
}

.ff-dropdown-item:hover {
  background-color: #f0f9f6;
  color: #005d41;
}

/* Prompt Library Styles - Completely Rewritten */

/* Dropdown Container */
.ff-dropdown-container {
  position: relative;
  width: 100%;
}

/* Centered Prompt Controls */
.ff-prompt-controls-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 0;
}

.ff-prompt-dropdown-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.ff-prompt-dropdown-label {
  text-align: center;
  margin-bottom: 1.25rem;
  font-size: 1.6rem;
  color: #2d3748;
  font-weight: 600;
}

.ff-dropdown-button-large {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1.5rem 2.5rem;
  background-color: #ffffff;
  border: 3px solid #005d41;
  border-radius: 0.75rem;
  color: #242424;
  cursor: pointer;
  font-size: 1.5rem;
  text-align: left;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
}

.ff-dropdown-button-large:hover {
  background-color: #f0f9f6;
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.ff-dropdown-button-large.active {
  border-color: #005d41;
  background-color: #f0f9f6;
  box-shadow: 0 8px 16px rgba(0, 93, 65, 0.2);
}

.ff-dropdown-button-large svg {
  width: 28px;
  height: 28px;
  transition: transform 0.3s;
}

.ff-dropdown-button-large.active svg {
  transform: rotate(180deg);
}

.ff-dropdown-menu-large {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 20;
  width: 100%;
  margin-top: 0.75rem;
  padding: 1rem 0;
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 0.75rem;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  max-height: 400px;
  overflow-y: auto;
}

.ff-dropdown-item {
  display: block;
  width: 100%;
  padding: 1.25rem 2.5rem;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  color: #4a5568;
  font-size: 1.3rem;
  transition: all 0.2s ease;
}

.ff-dropdown-item:hover {
  background-color: #f0f9f6;
  color: #005d41;
}

/* Prompt Cards Grid - Fixed to 3 columns */
.ff-prompts-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.75rem;
  margin-top: 3rem;
}

.ff-prompt-card {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 0.75rem;
  border: 1px solid #e6e6e6;
  text-decoration: none;
  color: inherit;
  transition: all 0.3s ease;
  height: 100%;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.ff-prompt-card:hover {
  transform: translateY(-6px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  border-color: #005d4140;
}

.ff-prompt-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.75rem;
  height: 100%;
}

.ff-prompt-title-wrapper {
  flex-grow: 1;
  margin-bottom: 1rem;
}

.ff-prompt-title {
  font-size: 1.35rem;
  font-weight: 600;
  color: #242424;
  line-height: 1.4;
  margin-bottom: 0.5rem;
}

.ff-prompt-category-badge {
  display: inline-block;
  background-color: #f0f9f6;
  color: #005d41;
  font-size: 0.95rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  margin-bottom: 1.25rem;
  border: 1px solid rgba(0, 93, 65, 0.2);
}

.ff-prompt-card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  border-top: 1px solid #f0f0f0;
  color: #005d41;
  font-weight: 500;
}

.ff-prompt-view-text {
  font-size: 1rem;
}

.ff-prompt-card-footer svg {
  width: 18px;
  height: 18px;
  transition: transform 0.2s ease;
}

.ff-prompt-card:hover .ff-prompt-card-footer svg {
  transform: translate(3px, -3px);
}

/* Loading and Error States */
.ff-resources-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 2rem;
  border-radius: 1rem;
  background-color: #f8f8f8;
  text-align: center;
  margin: 2rem auto;
  max-width: 700px;
  border: 1px solid #e6e6e6;
}

.ff-loading-spinner {
  border: 5px solid rgba(0, 93, 65, 0.1);
  border-radius: 50%;
  border-top: 5px solid #005d41;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 1.5rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Enhanced prompt cards with pseudo-element decorations */
.ff-prompt-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background: linear-gradient(90deg, #005d41, #1f8a6f);
  opacity: 0.8;
  transition: height 0.3s ease;
}

.ff-prompt-card:hover::before {
  height: 8px;
}

/* Responsive Styles */
@media (max-width: 1100px) {
  .ff-prompts-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
  
  .ff-prompt-dropdown-centered {
    max-width: 500px;
  }
  
  .ff-dropdown-button-large {
    font-size: 1.3rem;
    padding: 1.1rem 1.75rem;
  }
  
  .ff-prompt-title {
    font-size: 1.25rem;
  }
}

@media (max-width: 768px) {
  .ff-prompts-grid {
    grid-template-columns: 1fr;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .ff-prompt-dropdown-label {
    font-size: 1.3rem;
  }
  
  .ff-dropdown-button-large {
    font-size: 1.2rem;
    padding: 1rem 1.5rem;
  }
  
  .ff-dropdown-item {
    padding: 0.9rem 1.5rem;
    font-size: 1.1rem;
  }
  
  .ff-prompt-content {
    padding: 1.5rem;
  }
  
  .ff-prompt-title {
    font-size: 1.2rem;
  }
  
  .ff-prompt-category-badge {
    font-size: 0.9rem;
    padding: 0.4rem 0.8rem;
  }
}

@media (max-width: 576px) {
  .ff-prompt-dropdown-centered {
    max-width: 100%;
  }
  
  .ff-prompts-grid {
    grid-template-columns: 1fr;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .ff-prompt-content {
    padding: 1.5rem;
  }
  
  .ff-prompt-title {
    font-size: 1.2rem;
  }
}

.no-padding {
  padding: 0 !important;
}